import { Input, Typography } from 'antd';
import { useContext, useMemo, useCallback } from 'react';
import { ContextApp } from '../../../contexts/ContextApp';
import { ITenant } from '../../../interfaces';
import GraphqlService from '../../../services/graphql/GraphqlService';
import { ABM, Tools } from '../../../shared';
import { ExportableColumn } from '../../../shared/Exporter';
import { CustomMessage } from '../../CustomMessage';
import { notificationContext } from '../../../contexts/NotificationContext';

interface IUseTenantColumnNameProps {
  editForm?: ITenant;
  formColumnName?: string;
  formColumnTitle?: string;
  readOnly?: boolean;
  required?: boolean;
}

export const useTenantColumnName = (props: IUseTenantColumnNameProps) => {
  const { Text } = Typography;
  const {
    editForm,
    formColumnName,
    formColumnTitle,
    readOnly = false,
    required = true,
  } = props;

  const { t } = useContext(ContextApp);

  const { Query, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const findTenantByName = async (name: string): Promise<ITenant | null> => {
    try {
      return await customRequest({
        query: Query.tenant,
        variables: {
          input: {
            name: name,
          },
        },
      });
    } catch (err: any) {
      if (err.status_code !== 2 && err.status_code !== 500) {
        openNotification({
          type: 'error',
          msj: getErrorMessage(err),
          context: 'TenantPage.findTenantByName.1',
        });
      }
      return null;
    }
  };

  const validateName = useCallback(
    async (
      _rule: any,
      value: any,
      _validatorCallback: any,
    ): Promise<string> => {
      if (!Tools.validateTenantName(value))
        return Promise.reject(t('error.backend.tenantNameFormat'));
      return new Promise(async (resolve, reject) => {
        if (value) {
          try {
            const tenant = await findTenantByName(value);
            if (tenant && (!editForm || editForm.name !== tenant.name)) {
              reject(t('error.backend.nameNotAvailable'));
            } else {
              resolve('');
            }
          } catch (err) {
            resolve('');
          }
        } else {
          resolve('');
        }
      });
    },
    [editForm, findTenantByName],
  );

  const columnName: ExportableColumn<ITenant> = useMemo(
    () => ({
      export: true,
      className: 'name-cell',
      dataIndex: formColumnName || 'name',
      title: formColumnTitle || t('entity.name'),
      type: ABM.TYPE_COLUMN.STRING,
      formItemProps: {
        normalize: (value: string) => value?.trim(),
        validateTrigger: 'onBlur',
        rules: [
          {
            required: required,
            message: t('error.abm.nameRequired'),
          },
          {
            validator: validateName,
          },
        ],
      },
      render: (_: any, record: { name: string }) =>
        record.name ? (
          <Text
            style={{ width: 90 }}
            ellipsis={{
              tooltip: record.name,
            }}
          >
            {record.name}
          </Text>
        ) : (
          '-'
        ),
      renderFormItem: () => (
        <Input
          placeholder={`${t('action.input.enter')} ${t(
            'entity.name',
          ).toLocaleLowerCase()}`}
          minLength={4}
          readOnly={readOnly}
          style={{ color: readOnly ? '#00000080' : 'black' }}
        />
      ),
      align: 'left',
      sorter: true,
      hideInTable: false,
      hideInSearch: false,
      hideInForm: false,
    }),
    [validateName],
  );

  return { columnName };
};
