import { Alert, Button, Form, FormInstance, Input, Switch } from 'antd';
import Upload, { RcFile } from 'antd/lib/upload';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ContextApp } from '../contexts/ContextApp';
import { IAppSetting } from '../interfaces';
import { IDecisionItem } from '../interfaces/DecisionItems';
import GraphqlService from '../services/graphql/GraphqlService';
import { ExportableColumn } from '../shared/Exporter';
import { CustomMessage } from './CustomMessage';
import { EnumsValues } from '../enums/EnumsValues';
import { SaveForm } from '../components/common/ABM';
import { ActionType } from '@ant-design/pro-table';
import { notificationContext } from '../contexts/NotificationContext';

type Props = {
  actionRef?: React.MutableRefObject<ActionType | undefined>;
};

export const useImportProducts = (props: Props = {}) => {
  const { actionRef } = props;
  const [importModalVisible, setImportModalVisible] = useState<boolean>(false);
  const [errorFile, setErrorFile] = useState<string>();
  const [file, setFile] = useState<RcFile>();
  const [formLoading, setFormLoading] = useState(false);
  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );

  const { t, selectedTenantId } = useContext(ContextApp);

  const { Query, Mutation, customRequest, customFileRequest } =
    GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const importRef = useRef<FormInstance<any>>();

  const TITLE_IMPORT_FORM = `${t('action.import')}`;

  const getLimitMaxSizeFileSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.LimitMaxSizeFile },
        },
      });
      setMaxSizeFile(Number(data.setting_value));
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'useImportProducts.getLimitMaxSizeFileSetting.1',
      });
    }
  };

  useEffect(() => {
    getLimitMaxSizeFileSetting();
  }, []);

  const importProducts = useCallback(
    async (value: {
      separator?: string;
      sheet?: string;
      code_field: string;
      description_field: string;
      upload_file: any;
    }) => {
      delete value.upload_file;
      setFormLoading(true);
      try {
        if (!file) throw new Error(t('error.abm.fileRequired'));

        const fileArray = [
          {
            file,
            path: 'variables.file',
          },
        ];

        await customFileRequest(
          {
            mutation: Mutation.importProducts,
            variables: {
              input: { ...value, tenant_id: selectedTenantId },
              file: {
                filename: file.name,
                mimetype: file.type,
                encoding: 'base64',
              },
            },
          },
          fileArray,
        );
        setImportModalVisible(false);
        setErrorFile(undefined);
        setFile(undefined);
        openNotification({
          type: 'success',
          msj: t('message.createSuccess'),
          context: 'useImportProducts.importProducts.1',
        });
        if (actionRef) actionRef.current?.reload();
      } catch (error: any) {
        if (error.message) {
          setFormLoading(false);
          return openNotification({
            type: 'error',
            msj: getErrorMessage(error),
            context: 'useImportProducts.importProducts.3',
          });
        }
      }
      setFormLoading(false);
    },
    [file, selectedTenantId],
  );

  const columnsCSV = useMemo(
    (): ExportableColumn<IDecisionItem>[] => [
      {
        export: false,
        dataIndex: 'separator',
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.separatorRequired'),
            },
          ],
        },
        title: t('entity.separator'),
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.separator',
            ).toLocaleLowerCase()}`}
          />
        ),
      },
    ],
    [],
  );

  const columnsExcel = useMemo(
    (): ExportableColumn<IDecisionItem>[] => [
      {
        export: false,
        dataIndex: 'sheet',
        title: t('entity.sheet'),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.sheetRequired'),
            },
          ],
        },
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.sheet',
            ).toLocaleLowerCase()}`}
          />
        ),
      },
    ],
    [],
  );

  const columnsImport = useMemo(
    (): ExportableColumn<IDecisionItem>[] => [
      {
        export: false,
        dataIndex: 'upload_file',
        title: t('entity.file'),
        formItemProps: {
          rules: [
            {
              required: true,
              validator: () => {
                if (!file) {
                  return Promise.reject(t('error.abm.fileRequired'));
                }
                return Promise.resolve();
              },
            },
          ],
        },
        renderFormItem: () => (
          <Upload
            multiple={false}
            maxCount={1}
            fileList={file ? [file] : []}
            customRequest={(uploadRequestOptions) => {
              const { onSuccess, file } = uploadRequestOptions;
              const fileRc = file as RcFile;
              if (fileRc.size > maxSizeFile) {
                setErrorFile(t('error.abm.fileMaxSizeExceeded'));
              } else {
                setErrorFile(undefined);
              }
              setFile(fileRc);
              if (onSuccess) {
                onSuccess('');
              }
            }}
            onRemove={() => {
              setErrorFile(undefined);
              setFile(undefined);
            }}
            // accepts .csv .xls .xlsx
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              {errorFile ? (
                <Alert
                  style={{ marginTop: '14px' }}
                  message={errorFile}
                  type="error"
                  showIcon
                />
              ) : null}
              <Button>
                <span className="material-symbols-outlined">upload</span>
                {t('action.import')}
              </Button>
            </div>
          </Upload>
        ),
      },
      {
        export: false,
        dataIndex: 'code_field',
        title: `${t('entity.column')} ${t('entity.code')}`,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.codeRequired'),
            },
          ],
        },
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.codeColumnName',
            ).toLocaleLowerCase()}`}
          />
        ),
      },
      {
        export: false,
        dataIndex: 'description_field',
        title: `${t('entity.column')} ${t('entity.name')}`,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.nameRequired'),
            },
          ],
        },
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.productNameColumnName',
            ).toLocaleLowerCase()}`}
          />
        ),
      },
      {
        export: false,
        dataIndex: 'skip_repeated',
        title: t('action.skipRepeated'),
        renderFormItem: () => (
          <Form.Item
            valuePropName="checked"
            name="skip_repeated"
            style={{ margin: 0 }}
          >
            <Switch />
          </Form.Item>
        ),
      },
      ...(file?.type === 'text/csv' ? columnsCSV : []),
      ...(file?.type &&
      [
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(file.type)
        ? columnsExcel
        : []),
    ],
    [errorFile, file, columnsExcel, columnsCSV],
  );

  useEffect(() => {
    // setTimeout requerido, de lo contrario la validación se ejecuta con los valores previos
    setTimeout(() => importRef.current?.validateFields(['upload_file']), 0);
  }, [file, columnsImport]);

  const importProductsButton = (
    <Button
      type="primary"
      className="product-import-btn"
      onClick={() => {
        setImportModalVisible(true);
      }}
      icon={
        <span className="material-symbols-outlined product-import-icon">
          upload
        </span>
      }
    >
      {t('action.import')}
    </Button>
  );

  const importProductsSaveform = (
    <SaveForm
      loading={formLoading}
      title={TITLE_IMPORT_FORM}
      onCancel={() => {
        setImportModalVisible(false);
        setErrorFile(undefined);
        setFile(undefined);
      }}
      modalVisible={importModalVisible}
      onOk={(value) => {
        importProducts(value);
      }}
      columns={columnsImport}
      notIgnoreFalsyValues={true}
      formRef={importRef}
      saveFormFooterIcon={{
        reset: <></>,
      }}
      buttonCancel={true}
      buttonReset={false}
    />
  );

  return { importProductsButton, importProductsSaveform };
};
