import ProTable, { ActionType } from '@ant-design/pro-table';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
  Tooltip,
} from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ContextApp } from '../../contexts/ContextApp';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  FocusEvent,
  Dispatch,
  SetStateAction,
} from 'react';
import { EnumsValues } from '../../enums/EnumsValues';
import { IReceiptConceptExtracted } from '../../interfaces/ReceiptConceptExtracted';
import GraphqlService from '../../services/graphql/GraphqlService';
import './ReviewReceiptTaxes.less';
import { ILog } from '../../interfaces/Log';
import { SaveForm } from '../../components/common/ABM';
import ReviewReceiptCanvas from './ReviewReceiptCanvas';
import { FormInstance } from 'antd/es/form';
import { IWord } from '../../interfaces/Extraction';
import { IConceptMaster, IConceptType } from '../../interfaces/Concept';
import { IReceiptTaxType, ReceiptHC } from '../../interfaces/Receipt';
import { SaveFormColumns } from '../../components/common/ABM/SaveForm';
import { ABM } from '../../shared';
import { notificationContext } from '../../contexts/NotificationContext';
import { CustomMessage } from '../../hooks';
import { isNumeric } from './validations/isNumeric';
import validations from './validations/validations';
import { formatValueOnLoad } from '../../shared/formatValueOnLoad';
import { formatValueOnSave } from '../../shared/formatValueOnSave';

interface IReviewReceiptDiscountProps {
  receiptHeaderId?: number;
  file: string;
  marks: any;
  lastLog: ILog | undefined;
  receiptDiscountConfig: ReceiptHC | undefined;
  receiptTaxConfig: ReceiptHC | undefined;
  subtotalDiscount: number;
  setSubtotalDiscount: Dispatch<SetStateAction<number>>;
  setTaxDetails: Dispatch<
    SetStateAction<IReceiptConceptExtracted[] | undefined>
  >;
  taxDetails: IReceiptConceptExtracted[] | undefined;
  setDiscountDetails: Dispatch<
    SetStateAction<IReceiptConceptExtracted[] | undefined>
  >;
  discountDetails: IReceiptConceptExtracted[] | undefined;
  setConceptMasters: Dispatch<SetStateAction<IConceptMaster[]>>;
  conceptMasters: IConceptMaster[] | undefined;
  isFormChanged: React.MutableRefObject<boolean>;
}

export default function ReviewReceiptDiscount(
  props: IReviewReceiptDiscountProps,
) {
  const {
    receiptHeaderId,
    file,
    marks,
    lastLog,
    receiptDiscountConfig,
    receiptTaxConfig,
    subtotalDiscount,
    setSubtotalDiscount,
    setTaxDetails,
    taxDetails,
    setConceptMasters,
    conceptMasters,
    setDiscountDetails,
    discountDetails,
    isFormChanged,
  } = props;
  const { t, selectedTenantId } = useContext(ContextApp);
  const { Query, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  const [conceptTypes, setConceptTypes] = useState<IConceptType[]>();
  const [receiptTaxTypes, setReceiptTaxTypes] = useState<IReceiptTaxType[]>([]);
  const [newDiscount, setNewDiscount] = useState<boolean>(false);
  const [codeDisabled, setCodeDisabled] = useState<boolean>(true);
  const [isDiscount, setIsDiscount] = useState<boolean>(true);

  const [options, setOptions] = useState<SelectProps['options']>([]);
  const actionRef = useRef<ActionType>();

  const [addFormDiscounts, setAddFormDiscounts] = useState(false);
  const [updateFormDiscounts, setUpdateFormDiscounts] = useState(false);
  const [editForm, setEditFormValues] =
    useState<IReceiptConceptExtracted | undefined>(undefined);
  const createModalFormRef = useRef<FormInstance>();
  const updateModalFormRef = useRef<FormInstance>();
  const focusedInputTaxRef = useRef<string>();
  const lastFocusedInputDiscountRef = useRef<string>();
  const concatenatedContentRef = useRef<string>('');
  const [expandedLogs, setExpandedLogs] = useState<boolean>(false);

  const getConceptTypes = async () => {
    try {
      const data: IConceptType[] = await customRequest({
        query: Query.conceptTypes,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
        },
      });
      if (data) setConceptTypes(data);
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const getConceptMastersOptions = async (
    type: number = EnumsValues.ConceptType.descuento,
  ) => {
    if (typeof type === 'string') {
      type = Number(type);
    }

    if (conceptMasters) {
      const conceptMasterFiltered = conceptMasters.filter(
        (concept) => concept.concept_type_id === type,
      );
      const options1: SelectProps['options'] = [];
      if (type === EnumsValues.ConceptType.descuento) {
        options1.push({
          label: t('ReviewReceiptDiscounts.newDiscountConceptMaster'),
          value: 0,
        });
      }
      for (const concept of conceptMasterFiltered) {
        options1.push({
          label: concept.name,
          value: concept.id,
        });
      }
      setOptions(options1);
    }
  };

  useEffect(() => {
    let subtotal = 0;
    discountDetails?.forEach((element) => {
      subtotal = element.amount ? subtotal + element.amount : subtotal;
    });
    setSubtotalDiscount(Number(subtotal.toFixed(2)));
    if (actionRef.current) {
      actionRef.current.reload();
    }
  }, [discountDetails]);

  useEffect(() => {
    getConceptTypes();
    getReceiptTaxTypes();
    getConceptMastersOptions();
  }, [conceptMasters]);

  const onCanvasItemDetailClick = useCallback(
    async (
      content: string,
      shiftKey: boolean,
      form: FormInstance,
      element: IWord | null,
    ) => {
      if (
        focusedInputTaxRef.current &&
        element &&
        receiptDiscountConfig &&
        receiptTaxConfig
      ) {
        if (
          lastFocusedInputDiscountRef.current &&
          lastFocusedInputDiscountRef.current !== focusedInputTaxRef.current
        ) {
          const activeElements = document.querySelectorAll(
            '.pdf-canvas-item-active',
          );
          activeElements.forEach((element) => {
            element.classList.remove('pdf-canvas-item-active');
          });
          shiftKey = false;
        }
        const focusedElement = document.getElementById(
          focusedInputTaxRef.current,
        );

        if (
          !focusedElement ||
          focusedElement?.id === 'concept_master_id' ||
          focusedElement?.id === 'receipt_tax_type_id'
        )
          return;

        focusedElement.focus({ preventScroll: true });

        let inputConfig;

        if (
          Number(form.getFieldValue('concept_type_id')) ===
          EnumsValues.ConceptType.descuento
        ) {
          inputConfig = receiptDiscountConfig.fields.find(
            (item) => item.name === focusedElement?.id,
          );
        } else {
          inputConfig = receiptTaxConfig.fields.find(
            (item) => item.name === focusedElement?.id,
          );
        }

        const inputConfigType = inputConfig?.validation_type ?? undefined;

        if (shiftKey) {
          if (concatenatedContentRef.current !== '') {
            concatenatedContentRef.current =
              concatenatedContentRef.current +
              (inputConfig?.separator ? inputConfig.separator : ' ') +
              content;
          } else {
            concatenatedContentRef.current = content;
          }

          form.setFieldValue(
            focusedInputTaxRef.current,
            concatenatedContentRef.current,
          );
          lastFocusedInputDiscountRef.current = focusedInputTaxRef.current;
        } else {
          concatenatedContentRef.current = content;

          if (content.indexOf('$') !== -1 || content.indexOf('usd$')) {
            content = content.replace('$', '');
            content = content.replace('usd$', '');
          }

          content = validations({
            valueToValidate: content,
            type: inputConfigType,
          });

          form.setFieldValue(focusedInputTaxRef.current, content);
          lastFocusedInputDiscountRef.current = focusedInputTaxRef.current;
        }
        form.validateFields([focusedInputTaxRef.current]);
      }
    },
    [receiptDiscountConfig, receiptTaxConfig],
  );

  const onCreateCanvasItemDetailClick = useCallback(
    (content: string, shiftKey: boolean, element: IWord | null) => {
      if (createModalFormRef.current)
        onCanvasItemDetailClick(
          content,
          shiftKey,
          createModalFormRef.current,
          element,
        );
    },
    [onCanvasItemDetailClick],
  );

  const onUpdateCanvasItemDetailClick = useCallback(
    (content: string, shiftKey: boolean, element: IWord | null) => {
      if (updateModalFormRef.current)
        onCanvasItemDetailClick(
          content,
          shiftKey,
          updateModalFormRef.current,
          element,
        );
    },
    [onCanvasItemDetailClick],
  );

  const onFormInputTaxFocus = (
    event: FocusEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    focusedInputTaxRef.current = event.target.id;
  };

  function getValueFromFormObject(
    formObject: any,
    fieldConfig: {
      name: string;
      classification_id: number;
      type: string;
      hidden?: boolean | undefined;
      readonly?: boolean | undefined;
    },
  ) {
    const formField = formObject[fieldConfig.name];

    if (!formField && fieldConfig.classification_id === 1) {
      return null;
    } else if (fieldConfig.classification_id === 2 && !formField) {
      return null;
    }

    switch (fieldConfig.type) {
      case 'string':
        return String(formField);
      case 'number':
        return parseFloat(formatValueOnSave(String(formField)));
      default:
        return formField;
    }
  }

  const createReceiptDiscount = async (values: any) => {
    try {
      const dataToSendToServerDiscount: Record<string, any> = {};
      if (receiptDiscountConfig?.fields) {
        for (const fieldConfig of receiptDiscountConfig.fields) {
          if (fieldConfig.hidden) {
            continue;
          }

          const value = getValueFromFormObject(values, fieldConfig);
          dataToSendToServerDiscount[fieldConfig.name] = value;
        }
      }

      dataToSendToServerDiscount['tenant_id'] = selectedTenantId;
      dataToSendToServerDiscount['receipt_header_id'] = receiptHeaderId;

      delete dataToSendToServerDiscount.percentage;
      if (discountDetails) {
        dataToSendToServerDiscount['id'] =
          discountDetails.filter((ele) => ele.id < 0).length * -1 - 1;
      }
      if (Number(values.concept_master_id) && conceptMasters) {
        dataToSendToServerDiscount['concept_master_id'] =
          values.concept_master_id;

        dataToSendToServerDiscount['concept_master'] = conceptMasters.find(
          (concept) =>
            concept.id === dataToSendToServerDiscount['concept_master_id'],
        );
      } else {
        delete dataToSendToServerDiscount.concept_master_id;
        if (values.name && values['concept_master.code']) {
          dataToSendToServerDiscount['concept_master'] = {
            id: conceptMasters
              ? conceptMasters.filter((ele) => ele.id < 0).length * -1 - 1
              : -1,
            name: values.name,
            code: values['concept_master.code'],
            concept_type_id: values.concept_type_id,
            tenant_id: selectedTenantId,
            dts: null,
          };
        }
        if (
          setConceptMasters &&
          conceptMasters &&
          dataToSendToServerDiscount['concept_master']
        ) {
          setConceptMasters((oldConceptMaster) =>
            oldConceptMaster
              ? [
                  ...oldConceptMaster,
                  dataToSendToServerDiscount[
                    'concept_master'
                  ] as IConceptMaster,
                ]
              : oldConceptMaster,
          );
        }
      }

      if (setDiscountDetails && discountDetails) {
        setDiscountDetails((oldDiscountDetail) =>
          oldDiscountDetail
            ? [
                ...oldDiscountDetail,
                dataToSendToServerDiscount as IReceiptConceptExtracted,
              ]
            : oldDiscountDetail,
        );
      }

      openNotification({
        msj: t('ReviewReceiptDiscounts.message.createDiscountSuccessful'),
        type: 'success',
      });

      setAddFormDiscounts(false);
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const updateReceipDiscount = async (values: any) => {
    try {
      const dataToSendToServerDiscount: Record<string, any> = {};
      if (editForm?.id) dataToSendToServerDiscount['id'] = editForm.id;
      dataToSendToServerDiscount['tenant_id'] = selectedTenantId;
      dataToSendToServerDiscount['receipt_header_id'] = receiptHeaderId;
      const receiptTaxType = receiptTaxTypes.find(
        (ele) => values.receipt_tax_type_id === ele.id,
      );
      if (receiptTaxType) {
        dataToSendToServerDiscount['receipt_tax_type'] = receiptTaxType;
      }

      delete dataToSendToServerDiscount.percentage;
      if (receiptDiscountConfig?.fields) {
        for (const fieldConfig of receiptDiscountConfig.fields) {
          if (fieldConfig.hidden) {
            continue;
          }

          const value = getValueFromFormObject(values, fieldConfig);
          dataToSendToServerDiscount[fieldConfig.name] = value;
        }
      }

      if (Number(values.concept_master_id) && conceptMasters) {
        dataToSendToServerDiscount['concept_master_id'] =
          values.concept_master_id;
        const conceptMaster = conceptMasters.find(
          (ele) => ele.id === values.concept_master_id,
        );
        if (conceptMaster) {
          dataToSendToServerDiscount['concept_master'] = conceptMaster;
        }
      } else {
        delete dataToSendToServerDiscount.concept_master_id;
        if (values.name && values['concept_master.code']) {
          dataToSendToServerDiscount['concept_master'] = {
            id: conceptMasters
              ? conceptMasters.filter((ele) => ele.id < 0).length * -1 - 1
              : -1,
            name: values.name,
            code: values['concept_master.code'],
            concept_type_id: values.concept_type_id,
            tenant_id: selectedTenantId,
            dts: null,
          };
        }
        if (
          setConceptMasters &&
          conceptMasters &&
          dataToSendToServerDiscount['concept_master']
        ) {
          setConceptMasters((oldConceptMaster) =>
            oldConceptMaster
              ? [
                  ...oldConceptMaster,
                  dataToSendToServerDiscount[
                    'concept_master'
                  ] as IConceptMaster,
                ]
              : oldConceptMaster,
          );
        }
      }
      setDiscountDetails((oldTaxDetail) =>
        oldTaxDetail
          ? oldTaxDetail.map((item) =>
              item.id === dataToSendToServerDiscount['id']
                ? (dataToSendToServerDiscount as IReceiptConceptExtracted)
                : item,
            )
          : oldTaxDetail,
      );

      openNotification({
        msj: t('ReviewReceiptDiscounts.message.updateDiscountSuccessful'),
        type: 'success',
      });

      if (
        Number(values.concept_type_id) === EnumsValues.ConceptType.impuesto &&
        taxDetails
      ) {
        setTaxDetails((oldTaxDetail) =>
          oldTaxDetail
            ? [
                ...oldTaxDetail,
                dataToSendToServerDiscount as IReceiptConceptExtracted,
              ]
            : oldTaxDetail,
        );

        setDiscountDetails((oldDiscountDetail) =>
          oldDiscountDetail
            ? oldDiscountDetail.filter(
                (item) => item.id !== dataToSendToServerDiscount['id'],
              )
            : oldDiscountDetail,
        );
      }

      setEditFormValues(undefined);
      setUpdateFormDiscounts(false);
    } catch (error: any) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const removeRecordDiscount = (discountId: number) => {
    Modal.confirm({
      content: <div>{t('ReviewReceiptDiscounts.confirmRemoveDiscount')}</div>,
      icon: (
        <span className="material-symbols-outlined receipt-taxes-exclamation-icon">
          error
        </span>
      ),
      onOk: () => {
        deleteDiscount(discountId);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const deleteDiscount = async (discountId: number) => {
    setDiscountDetails(
      discountDetails?.filter((discount) => discount.id !== discountId),
    );
    isFormChanged.current = true;
    openNotification({
      msj: t('ReviewReceiptDiscounts.message.deleteDiscountSuccessful'),
      type: 'success',
    });
  };

  const getReceiptTaxTypes = async () => {
    try {
      const data: IReceiptTaxType[] = await customRequest({
        query: Query.receiptTaxTypes,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
        },
      });

      if (data) {
        setReceiptTaxTypes(data);
      }
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
      });
    }
  };

  const columns = useCallback(
    (_editMode: boolean): SaveFormColumns<IReceiptConceptExtracted>[] => [
      {
        title: t('entity.type'),
        dataIndex: 'concept_type_id',
        key: 'concept_type_id',
        type: ABM.TYPE_COLUMN.NUMBER,
        render: (_, record) => <div>{record.label}</div>,
        renderFormItem: () => (
          <Form.Item name="concept_type_id">
            <Select
              id="concept_type_id"
              placeholder={t('ReviewReceipt.message.selectOption')}
              onFocus={onFormInputTaxFocus}
              getPopupContainer={(node) => node.parentNode}
              options={(_editMode
                ? conceptTypes
                  ? conceptTypes
                  : []
                : conceptTypes
                ? [conceptTypes[1]]
                : []
              ).map((conceptType) => ({
                label: conceptType.name,
                value: String(conceptType.id),
              }))}
              onChange={(selectedValue) => {
                if (
                  Number(selectedValue) === EnumsValues.ConceptType.descuento
                ) {
                  setNewDiscount(false);
                  setCodeDisabled(true);
                  setIsDiscount(true);
                  if (_editMode) {
                    let actualAmount =
                      updateModalFormRef.current?.getFieldValue('amount');
                    updateModalFormRef.current?.setFieldValue(
                      'amount',
                      actualAmount && !actualAmount.includes('-')
                        ? '-' + actualAmount
                        : actualAmount,
                    );
                  }
                } else {
                  setIsDiscount(false);
                  if (_editMode) {
                    let actualAmount =
                      updateModalFormRef.current?.getFieldValue('amount');
                    updateModalFormRef.current?.setFieldValue(
                      'amount',
                      actualAmount && actualAmount.includes('-')
                        ? actualAmount.replace(/-/g, '')
                        : actualAmount,
                    );
                  }
                }

                if (_editMode) {
                  updateModalFormRef.current?.setFieldValue(
                    'concept_master_id',
                    undefined,
                  );
                  updateModalFormRef.current?.setFieldValue(
                    'concept_master.code',
                    undefined,
                  );
                } else {
                  createModalFormRef.current?.setFieldValue(
                    'concept_master_id',
                    undefined,
                  );
                  createModalFormRef.current?.setFieldValue(
                    'concept_master.code',
                    undefined,
                  );
                }
                getConceptMastersOptions(selectedValue);
              }}
            ></Select>
          </Form.Item>
        ),
        formItemProps: {
          name: 'concept_type_id',
        },
        align: 'right',
        hideInTable: true,
      },
      {
        dataIndex: 'concept_master.id',
        key: 'concept_master_id',
        render: (_, record) => <div>{record.label}</div>,
        renderFormItem: () => (
          <Form.Item
            name="concept_master_id"
            label={
              t('integrationSetting.concept') +
              (requiredInput('concept_master.id')
                ? t('ReviewReceipt.optional')
                : '')
            }
          >
            <Select
              id="concept_master_id"
              placeholder={t('ReviewReceipt.message.selectOption')}
              onFocus={onFormInputTaxFocus}
              getPopupContainer={(node) => node.parentNode}
              options={options}
              onChange={(selectedValue) => {
                const selectedOption = (conceptMasters || []).find(
                  (conceptType) => conceptType.id === selectedValue,
                );

                if (selectedOption) {
                  setCodeDisabled(true);
                  setNewDiscount(false);
                  if (_editMode) {
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      selectedOption.id,
                    );
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      selectedOption.code,
                    );
                  } else {
                    createModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      selectedOption.id,
                    );
                    createModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      selectedOption.code,
                    );
                  }
                } else {
                  setCodeDisabled(false);
                  setNewDiscount(true);
                  if (_editMode) {
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      0,
                    );
                    updateModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      undefined,
                    );
                  } else {
                    createModalFormRef.current?.setFieldValue(
                      'concept_master_id',
                      0,
                    );
                    createModalFormRef.current?.setFieldValue(
                      'concept_master.code',
                      undefined,
                    );
                  }
                }
              }}
            ></Select>
          </Form.Item>
        ),
        formItemProps: {
          name: 'concept_master_id',
          initialValue:
            editForm && editForm.concept_master?.dts === null
              ? editForm.concept_master?.id
              : undefined,
        },
        align: 'right',
        hideInTable: true,
      },
      {
        title: t('entity.name'),
        dataIndex: 'name',
        key: 'name',
        renderFormItem: () => {
          return (
            <Form.Item
              name="name"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue('concept_master_id') ===
                      EnumsValues.ConstNumbers.zero
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            t('ReviewReceiptDiscounts.message.nameRequired'),
                          ),
                        );
                      }
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              dependencies={['concept_master_id']}
            >
              <Input
                id="name"
                onFocus={onFormInputTaxFocus}
                placeholder={t('ReviewReceiptDiscounts.placeholder.name')}
              />
            </Form.Item>
          );
        },
        formItemProps: {
          name: 'name',
        },
        hideInTable: true,
        align: 'right',
        hideInForm: !newDiscount,
      },
      {
        title: t('entity.code'),
        dataIndex: 'concept_master.code',
        key: 'concept_master.code',
        render: (_, record) => <div>{record.label}</div>,
        renderFormItem: () => {
          return (
            <Form.Item
              name="concept_master.code"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue('concept_master_id') ===
                      EnumsValues.ConstNumbers.zero
                    ) {
                      if (!value) {
                        return Promise.reject(
                          new Error(
                            t('ReviewReceiptDiscounts.message.codeRequired'),
                          ),
                        );
                      }
                      return Promise.resolve();
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
              dependencies={['concept_master_id']}
            >
              <Input
                disabled={codeDisabled}
                id="concept_master.code"
                onFocus={onFormInputTaxFocus}
                placeholder={t(
                  'ReviewReceiptDiscounts.placeholder.conceptMasterCode',
                )}
              />
            </Form.Item>
          );
        },
        formItemProps: {
          name: 'concept_master.code',
          initialValue:
            editForm && editForm.concept_master?.dts === null
              ? editForm.concept_master?.code
              : undefined,
        },
        align: 'right',
        hideInTable: true,
      },
      {
        title: t('entity.description'),
        dataIndex: 'label',
        key: 'label_table',
        render: (_, record) => <div>{record.label}</div>,
        align: 'left',
        hideInForm: true,
      },
      {
        title: t('entity.conceptMasterId'),
        dataIndex: 'concept_master.name',
        key: 'concept_master.name',
        render: (_, record) => <div>{record.concept_master?.name}</div>,
        align: 'left',
        hideInForm: true,
      },
      {
        dataIndex: 'label',
        key: 'label',
        renderFormItem: () => (
          <Form.Item
            name="label"
            label={
              t('ReviewReceiptDiscounts.newDiscount') +
              (requiredInput('label') ? t('ReviewReceipt.optional') : '')
            }
          >
            <Input
              id="label"
              onFocus={onFormInputTaxFocus}
              placeholder={t('ReviewReceiptDiscounts.placeholder.label')}
            />
          </Form.Item>
        ),
        formItemProps: {
          name: 'label',
        },
        hideInTable: true,
      },
      {
        dataIndex: 'receipt_tax_type_id',
        key: 'receipt_tax_type_id',
        renderFormItem: () => (
          <Form.Item
            name="receipt_tax_type_id"
            label={
              t('entity.taxRateInTable') +
              (requiredInput('receipt_tax_type_id')
                ? t('ReviewReceipt.optional')
                : '')
            }
          >
            <Select
              id="receipt_tax_type_id"
              placeholder={t('ReviewReceipt.message.selectOption')}
              onFocus={onFormInputTaxFocus}
              getPopupContainer={(node) => node.parentNode}
              options={(receiptTaxTypes || []).map((conceptType) => ({
                label: conceptType.name,
                value: conceptType.id,
              }))}
              allowClear
            ></Select>
          </Form.Item>
        ),
        formItemProps: {
          name: 'receipt_tax_type_id',
          initialValue: editForm ? editForm.receipt_tax_type?.id : undefined,
        },
        align: 'right',
        hideInForm: isDiscount,
        hideInTable: true,
      },
      {
        title: t('ReceiptTray.modalViewReceipt.detailsTab.amount'),
        dataIndex: 'amount',
        key: 'amount',
        render: (_, record) =>
          record.amount != null && isNumeric(record.amount)
            ? formatValueOnLoad(record.amount)
            : null,
        align: 'right',
        hideInForm: true,
      },
      {
        dataIndex: 'amount',
        key: 'amount',
        render: (_, record) =>
          record.amount != null && isNumeric(record.amount)
            ? formatValueOnLoad(record.amount)
            : null,
        renderFormItem: () => (
          <Form.Item
            name="amount"
            label={
              t('ReceiptTray.modalViewReceipt.detailsTab.amount') +
              (requiredInput('amount') ? t('ReviewReceipt.optional') : '')
            }
          >
            <Input
              id="amount"
              onFocus={onFormInputTaxFocus}
              placeholder={t('ReviewReceiptDiscounts.placeholder.amount')}
            />
          </Form.Item>
        ),
        formItemProps: {
          name: 'amount',
          normalize: (value: string) => {
            return isDiscount
              ? !value.includes('-')
                ? '-' + value
                : value
              : value;
          },
        },
        hideInTable: true,
      },
      {
        title: t('entity.actions'),
        dataIndex: 'option',
        valueType: 'option',
        key: 'option',
        fixed: 'right',
        width: 'auto',
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        align: 'right',
        render: (_, record) => (
          <>
            <Tooltip
              key="edit_setting"
              title={t('ReviewReceiptDiscounts.editDiscount')}
            >
              <span
                className="material-symbols-outlined pointer receipt-taxes-discount-action-icon"
                onClick={() => {
                  setEditFormValues(record);
                  setUpdateFormDiscounts(true);
                  setNewDiscount(false);
                  setCodeDisabled(true);
                  setIsDiscount(true);
                }}
              >
                edit
              </span>
            </Tooltip>
            <Tooltip
              key="remove_setting"
              title={t('ReviewReceiptDiscounts.removeDiscount')}
            >
              <span
                className="material-symbols-outlined pointer receipt-taxes-discount-action-icon"
                onClick={() => {
                  removeRecordDiscount(record.id);
                }}
              >
                delete
              </span>
            </Tooltip>
          </>
        ),
      },
    ],
    [
      editForm,
      discountDetails,
      conceptMasters,
      newDiscount,
      codeDisabled,
      isDiscount,
      options,
      conceptTypes,
      taxDetails,
    ],
  );

  const requiredInput = useCallback(
    (inputName: string) => {
      const field = receiptDiscountConfig?.fields.find(
        (f) => f.name === inputName,
      );
      if (field) {
        return field.classification_id === 2;
      }

      return false;
    },
    [receiptDiscountConfig],
  );

  return (
    <>
      <Collapse className="collapse-receipt-item" bordered={false}>
        <CollapsePanel
          header={
            <div className="collapse-receipt-item-title">
              <div className="collapse-receipt-item-title-header">
                {t('ReviewReceiptDiscounts.titleTab')}
              </div>
              <div className="collapse-receipt-item-title-header">
                {t('ReviewReceiptDiscounts.totalDiscountsTab')}:{' '}
                <b>
                  {t('entity.ARSSymbol')} {formatValueOnLoad(subtotalDiscount)}
                </b>
              </div>
            </div>
          }
          key="2"
        >
          <ProTable
            dataSource={discountDetails}
            columns={columns(false)}
            actionRef={actionRef}
            pagination={false}
            scroll={{ y: 300 }}
            size="small"
            className="receipt-taxes-protable"
            search={false}
            toolBarRender={false}
            rowKey="key"
            bordered={false}
          />
          <Tooltip
            key="btn-add-item"
            title={t('ReviewReceiptDiscounts.addDiscount')}
          >
            <Button
              className="add-receipt-taxes-discount"
              icon={
                <span className="material-symbols-outlined receipt-taxes-discount-add-icon">
                  add
                </span>
              }
              onClick={() => {
                setAddFormDiscounts(true);
                setNewDiscount(false);
                setIsDiscount(true);
              }}
            >
              {t('ReviewReceiptDiscounts.createDiscount')}
            </Button>
          </Tooltip>
        </CollapsePanel>
      </Collapse>
      {editForm && (
        <Modal
          destroyOnClose
          title={t('ReviewReceiptDiscounts.editDiscount')}
          className="receipt-tax-saveForm"
          open={updateFormDiscounts}
          onCancel={() => {
            setUpdateFormDiscounts(false);
            setEditFormValues(undefined);
          }}
          footer={null}
          width="90vw"
        >
          <Row>
            <Col span={7}>
              <SaveForm
                loading={false}
                title={t('ReviewReceiptDiscounts.editDiscount')}
                values={{
                  ...editForm,
                  concept_type_id: EnumsValues.ConceptType.descuento,
                  amount: isNumeric(editForm.amount)
                    ? formatValueOnLoad(editForm.amount)
                    : null,
                  receipt_tax_type_id: undefined,
                  concept_master_id: undefined,
                  concept_master: undefined,
                }}
                modalVisible={updateFormDiscounts}
                columns={columns(true)}
                renderAsChildren
                formRef={updateModalFormRef}
                onOk={(value) => {
                  updateReceipDiscount(value);
                  isFormChanged.current = true;
                }}
                onCancel={() => {
                  setUpdateFormDiscounts(false);
                  setEditFormValues(undefined);
                }}
                saveFormFooterIcon={{
                  reset: <></>,
                }}
                buttonCancel={true}
                buttonReset={false}
              />
            </Col>
            <Col span={17}>
              <ReviewReceiptCanvas
                file={file}
                pages={marks}
                onCanvasItemClick={onUpdateCanvasItemDetailClick}
                lastLog={lastLog}
                expandedLogs={expandedLogs}
                setExpandedLogs={setExpandedLogs}
                shouldValidateButtonBeVisible={true}
              />
            </Col>
          </Row>
        </Modal>
      )}
      <Modal
        destroyOnClose
        title={t('ReviewReceiptDiscounts.addDiscount')}
        className="receipt-tax-saveForm"
        open={addFormDiscounts}
        onCancel={() => {
          setAddFormDiscounts(false);
        }}
        footer={null}
        width="90vw"
      >
        <Row>
          <Col span={7}>
            <SaveForm
              loading={false}
              title={t('ReviewReceiptDiscounts.addDiscount')}
              values={{
                concept_type_id: EnumsValues.ConceptType.descuento,
              }}
              modalVisible={addFormDiscounts}
              columns={columns(false)}
              renderAsChildren
              formRef={createModalFormRef}
              onOk={(value) => {
                createReceiptDiscount(value);
                isFormChanged.current = true;
              }}
              onCancel={() => {
                setAddFormDiscounts(false);
              }}
              saveFormFooterIcon={{
                reset: <></>,
              }}
              buttonCancel={true}
              buttonReset={false}
            />
          </Col>
          <Col span={17}>
            <ReviewReceiptCanvas
              file={file}
              pages={marks}
              onCanvasItemClick={onCreateCanvasItemDetailClick}
              lastLog={lastLog}
              expandedLogs={expandedLogs}
              setExpandedLogs={setExpandedLogs}
              shouldValidateButtonBeVisible={false}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
}
