import ProTable, { ActionType } from '@ant-design/pro-table';
import { Button, Input, Modal, Select, Tooltip } from 'antd';
import { SaveForm } from '../../components/common/ABM';
import {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, Tools } from '../../shared';
import ModalConfigureMultiple from '../../components/common/ModalConfigureMultiple/Index';
import { IRole } from '../../interfaces/role';
import { IPermission } from '../../interfaces/permission';
import { isPlatform } from '@ionic/react';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import './RolePage.less';
import { notificationContext } from '../../contexts/NotificationContext';
import ToolbarFilterOrder from '../../components/common/ToolbarFilterOrder/ToolbarFilterOrder';
/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ['name', 'role_id'];
const LIST_SORTER = ['name'];

export default function RolePage() {
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [editForm, setEditFormValues] = useState<any>({});
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [managePermissionsModalVisible, setManagePermissionsModalVisible] =
    useState(false);
  const [dataManagePermissions, setDataManagePermissions] = useState<IRole>({
    id: 0,
    name: '',
    is_system_role: false,
    editable: false,
  });
  const [dataRolePermission, setDataRolePermission] = useState<IPermission[]>(
    [],
  );
  const [rolesCombo, setRolesCombo] = useState<IRole[]>([]);
  const [modalConfigureMultipleLoading, setModalConfigureMultipleLoading] =
    useState<boolean>(false);
  const [dataPermissions, setDataPermissions] = useState<IPermission[]>([]);
  // services and hooks
  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });
  const { Query, Mutation, customRequest } = GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const reloadRoleCombo = useRef<boolean>(true);
  const customParamsRef = useRef<any>({});

  // contexts
  const { functions, t } = useContext(ContextApp);

  // methods

  /**
   * Se configura por cada ABM diferente
   */
  const TITLE_CREATE_FORM = `${t('action.create')} ${t(
    'entity.role',
  ).toLocaleLowerCase()}`;
  const TITLE_UPDATE_FORM = `${t('action.modify')} ${t(
    'entity.role',
  ).toLocaleLowerCase()}`;

  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'role-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'role-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };
  useLayoutEffect(() => {
    renderFilterText();
  }, []);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const getPermissions = async () => {
    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
      });
      setDataPermissions(data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        msj: getErrorMessage(error),
        type: 'error',
        context: 'RolePage.getPermissions.1',
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const getRoles = async () => {
    try {
      const data: IRole[] = await customRequest({
        query: Query.roles,
      });
      setRolesCombo(() => data);
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'RolePage.getRoles.1',
        msj: getErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    getPermissions();
    getRoles();
  }, []);

  const getRolePermission = async (id_role: number) => {
    setModalConfigureMultipleLoading(() => true);
    try {
      const data: IPermission[] = await customRequest({
        query: Query.permissions,
        variables: { filter: { role_id: id_role } },
      });
      setDataRolePermission(() => data);
      setModalConfigureMultipleLoading(() => false);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'RolePage.getRolePermission.1',
        msj: getErrorMessage(error),
      });
      setModalConfigureMultipleLoading(() => false);
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const request = async (params: any) => {
    delete variables.current.filter;
    delete variables.current.orderBy;
    variables.current = {};
    variables.current.filter = {};
    const search: any = ABM.valuesResult(params);

    if (searchText) {
      variables.current.searchText = searchText;
    } else {
      delete variables.current.searchText;
    }

    if (customParamsRef.current && customParamsRef.current.filter) {
      Object.entries(customParamsRef.current.filter).forEach(([key, value]) => {
        if (
          Tools.isDefined(value) &&
          (!Array.isArray(value) || value.length > 0)
        ) {
          variables.current.filter[key] = value;
        }
      });
    }

    LIST_SORTER.forEach((element) => {
      try {
        if (search.sorter[element]) {
          if (!variables.current.orderBy) {
            variables.current.orderBy = {};
          }
          variables.current.orderBy.direction =
            Tools.getTypeOrderByTableSortParam(search.sorter[element]);
          variables.current.orderBy.field = element;
        }
      } catch (error) {
        // este error esta contemplado porque seguro el filtro que busca no se encuentra
      }
    });
    try {
      const data: IRole[] = await customRequest({
        query: Query.roles,
        variables: variables.current,
      });
      setDataTable(data);
      return {
        current: 1,
        data: data,
        pageSize: '1',
        success: true,
        total: data.length,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'RolePage.request.1',
        msj: getErrorMessage(error),
      });
      return {
        current: 1,
        data: [],
        pageSize: '1',
        success: true,
        total: 0,
      };
    }
  };

  const createRole = async (value: any) => {
    setFormLoading(() => true);
    try {
      await customRequest({
        mutation: Mutation.createRole,
        variables: {
          input: value,
        },
      });
      setCreateModalVisible(false);
      openNotification({
        type: 'success',
        context: 'TableRole.createRole.1',
        msj: t('message.createSuccess'),
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          context: 'TableRole.createRole.3',
          msj: getErrorMessage(error),
        });
      }
      openNotification({
        type: 'error',
        context: 'TableRole.createRole.3',
        msj: t('error.abm.create'),
      });
    }
    setFormLoading(() => false);
  };

  const updateRolePermissions = async (
    id: number,
    addPermissions: number[],
    deletePermissions: number[],
  ) => {
    if (!addPermissions.length && !deletePermissions.length) {
      return;
    }
    try {
      await customRequest({
        mutation: Mutation.upsertRolePermission,
        variables: {
          input: {
            role_id: id,
            addPermissionIds: addPermissions,
            deletePermissionIds: deletePermissions,
          },
        },
      });
      openNotification({
        type: 'success',
        context: 'TableRole.updateRolePermissions.1',
        msj: t('message.updateSuccess'),
      });
      setManagePermissionsModalVisible(false);
      if (actionRef.current?.reset) {
        reloadRoleCombo.current = true;
        actionRef.current.reset();
      }
    } catch (error: any) {
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          context: 'TableRole.updateRolePermissions.3',
          msj: getErrorMessage(error),
        });
      }
      openNotification({
        type: 'error',
        context: 'TableRole.updateRolePermissions.3',
        msj: t('error.abm.update'),
      });
    }
    setFormLoading(() => false);
  };

  const updateRole = async (value: any) => {
    setFormLoading(() => true);
    try {
      await customRequest({
        mutation: Mutation.updateRole,
        variables: { input: { ...value, id: parseInt(editForm.id) } },
      });
      openNotification({
        type: 'success',
        context: 'TableRole.updateRole.1',
        msj: t('message.updateSuccess'),
      });
      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(() => false);
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          context: 'TableRole.updateRole.3',
          msj: getErrorMessage(error),
        });
      }
      openNotification({
        type: 'error',
        context: 'TableRole.updateRole.3',
        msj: t('error.abm.update'),
      });
    }
    setFormLoading(() => false);
  };

  const removeConfirmedRole = async (value: any) => {
    try {
      await customRequest({
        mutation: Mutation.deleteRole,
        variables: {
          id: parseInt(value.id),
        },
      });
      openNotification({
        type: 'success',
        context: 'Role.removeConfirmedRole.1',
        msj: t('message.deleteSuccess'),
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'Role.removeConfirmedRole.3',
        msj: getErrorMessage(error),
      });
    }
  };

  const removeRole = (value: any) => {
    Modal.confirm({
      content: (
        <>
          <div>¿Seguro que desea eliminar el rol {value.name}?</div>
        </>
      ),
      icon: (
        <span className="material-symbols-outlined rolePage-exclamation-icon">
          error
        </span>
      ),
      onOk: () => {
        removeConfirmedRole(value);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const columns = useCallback(
    (): ExportableColumn<IRole>[] => [
      {
        export: false,
        dataIndex: 'id',
        title: 'id',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: true,
        type: ABM.TYPE_COLUMN.NUMBER,
      },
      {
        export: true,
        dataIndex: 'name',
        title: t('entity.role'),
        type: ABM.TYPE_COLUMN.STRING,
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.roleRequired'),
            },
          ],
        },
        render: (_: any, record: { name: any }) => record.name || '-',
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.role',
            ).toLocaleLowerCase()}`}
            minLength={4}
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: false,
        dataIndex: 'role_id',
        title: t('entity.role'),
        renderFormItem: () => (
          <Select
            options={rolesCombo.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
            getPopupContainer={(node) => node.parentNode}
            placeholder={`${t('action.input.select')} ${t(
              'entity.role',
            ).toLocaleLowerCase()}`}
            mode="multiple"
            allowClear
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        title: t('entity.op'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleUpdate,
            ) &&
              !record.is_system_role && (
                <Tooltip
                  key="edit_rol_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title={`${t('action.modify')} ${t(
                    'entity.role',
                  ).toLocaleLowerCase()}`}
                >
                  <span
                    className="material-symbols-outlined pointer rolePage-action-icon"
                    onClick={() => {
                      handleUpdateModalVisible(true);
                      setEditFormValues(record);
                    }}
                  >
                    edit
                  </span>
                </Tooltip>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleUpdate,
            ) &&
              !record.is_system_role && (
                <>
                  <Tooltip
                    key="manage_permissions_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title={`${t('action.manage')} ${t(
                      'entity.permissions',
                    ).toLocaleLowerCase()}`}
                  >
                    <span
                      className="material-symbols-outlined pointer rolePage-action-icon"
                      onClick={() => {
                        setManagePermissionsModalVisible(true);
                        setDataManagePermissions(() => record);
                        getRolePermission(record.id);
                      }}
                    >
                      build
                    </span>
                  </Tooltip>
                </>
              )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleDelete,
            ) &&
              !record.is_system_role && (
                <>
                  <Tooltip
                    key="remove_rol_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title={`${t('action.remove')} ${t(
                      'entity.role',
                    ).toLocaleLowerCase()}`}
                  >
                    <span
                      className="material-symbols-outlined pointer rolePage-action-icon"
                      onClick={() => {
                        removeRole(record);
                      }}
                    >
                      delete
                    </span>
                  </Tooltip>
                </>
              )}
          </>
        ),
      },
    ],
    [dataTable],
  );
  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find((element) => element === value.dataIndex) &&
        value.hideInTable === false
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  return (
    <>
      <ProTable<IRole>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        actionRef={actionRef}
        className="protable-role"
        rowKey="id"
        size="small"
        options={{
          density: false,
          reload: false,
        }}
        search={false}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IRole>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <ToolbarFilterOrder
            onSearch={handleSearch}
            showButtonNew={false}
            columns={columns()}
            onFinishFilter={(values) => {
              customParamsRef.current.filter = values;
              if (actionRef.current?.reload) {
                actionRef.current.reload(true);
              }
            }}
            filterNames={INPUT_SEARCH_PLACEHOLDER}
          />,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.RoleCreate,
            ) && (
              <Button
                type="primary"
                className="rolePage-add-btn"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
              >
                <span className="material-symbols-outlined rolePage-add-icon">
                  add
                </span>{' '}
                {t('entity.new')}
              </Button>
            )}
          </>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        modalVisible={createModalVisible}
        onOk={async (value) => createRole(value)}
        columns={columns()}
        saveFormFooterIcon={{
          reset: <></>,
        }}
        buttonCancel={true}
        buttonReset={false}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={editForm}
          columns={columns()}
          onOk={async (value) => updateRole(value)}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues({});
          }}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
      {Authorization.security(
        functions,
        EnumsValues.Functions.RolePermissionUpdate,
      ) && (
        <ModalConfigureMultiple<IRole>
          renderLabel={(entity) => entity.name}
          checks={dataPermissions}
          data={[
            dataManagePermissions.id,
            dataRolePermission?.map((dataRole) => dataRole.id) || [],
          ]}
          onCancel={() => {
            setManagePermissionsModalVisible(false);
          }}
          onOk={(id_role, add_permissions, delete_permissions) => {
            return updateRolePermissions(
              id_role,
              add_permissions,
              delete_permissions,
            );
          }}
          modalVisible={managePermissionsModalVisible}
          titleModal={`${t('action.manage')} ${t('entity.permissions')}`}
          loading={modalConfigureMultipleLoading}
        />
      )}
    </>
  );
}
