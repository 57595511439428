import { useState, useLayoutEffect } from 'react';

const useGetElementHeight = (selector: string) => {
  const [height, setHeight] = useState(0);

  useLayoutEffect(() => {
    const destinationElement = document.querySelector(selector);
    if (destinationElement) {
      setHeight(destinationElement.clientHeight);
    } else {
      console.warn(`Element with selector ${selector} not found`);
    }
  }, [selector]);

  return height;
};

export default useGetElementHeight;
