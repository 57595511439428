import ProTable, { ActionType, ColumnsState } from '@ant-design/pro-table';
import {
  Alert,
  Button,
  Form,
  Modal,
  Select,
  Tooltip,
  Upload,
  Image,
  Spin,
  Input,
} from 'antd';
import { SaveForm } from '../../components/common/ABM';
import imageNotFound from '../../assets/imageNotFound.jpg';
import {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
  useMemo,
  useLayoutEffect,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { ABM, Authorization, Tools } from '../../shared';
import { isPlatform } from '@ionic/react';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues, TenantStatus } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { ITenant } from '../../interfaces';
import { RcFile } from 'antd/lib/upload';
import { IAppSetting } from '../../interfaces/AppSetting';
import { tooltipTrigger } from '../../shared/antdUtils';
import './TenantPage.less';
import { ITenantStatus } from '../../interfaces/Tenant';
import { useTenantColumnName } from '../../hooks/input/tenant/useTenantColumnName';
import { useTenantColumnBillingName } from '../../hooks/input/tenant/useTenantColumnBillingName';
import { useTenantColumnAddress } from '../../hooks/input/tenant/useTenantColumnAddress';
import { useTenantColumnTaxType } from '../../hooks/input/tenant/useTenantColumnTaxType';
import { useTenantColumnTaxNumber } from '../../hooks/input/tenant/useTenantColumnTaxNumber';
import { ITarget } from '../../interfaces/Target';
import { notificationContext } from '../../contexts/NotificationContext';
import ToolbarFilterOrder from '../../components/common/ToolbarFilterOrder/ToolbarFilterOrder';

/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = [
  'name',
  'billing_name',
  'address',
  'tax_type_id',
  'tax_number',
  'targetName',
];
const LIST_SORTER = ['name', 'id'];

export default function TenantPage() {
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<ITenant | undefined>(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<ITenant[]>([]);
  const [tenantStatesCombo, setTenantStatesCombo] = useState<ITenantStatus[]>(
    [],
  );

  const [maxSizeFile, setMaxSizeFile] = useState<number>(
    EnumsValues.SystemLimits.MaxSizeOfFiles,
  );
  const [errorFile, setErrorFile] = useState<string>();
  const [urlFile, setUrlFile] = useState<string>();
  const [fileMetaData, setFileMetaData] =
    useState<RcFile & { filename?: string }>();
  const [uploadImage, setUploadImage] = useState<boolean>(false);
  const [deleteCurrentImage, setDeleteCurrentImage] = useState<boolean>(false);
  const [loadingTenantPicture, setLoadingTenantPicture] =
    useState<boolean>(false);
  const [loadedPictureId, setLoadedPictureId] = useState<number | undefined>();
  const [previewImageTenantId, setPreviewImageTenantId] = useState<number>();
  const [targets, setTargets] = useState<ITarget[]>([]);

  // services and hooks
  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });
  const { columnName } = useTenantColumnName({ editForm });
  const { columnBillingName } = useTenantColumnBillingName({});
  const { columnAddress } = useTenantColumnAddress({});
  const { columnTaxType, selectedTaxType, setSelectedTaxType, taxTypesCombo } =
    useTenantColumnTaxType({});
  const { columnTaxNumber } = useTenantColumnTaxNumber({
    taxTypesCombo,
    selectedTaxType,
  });

  const { Query, Mutation, customRequest, customFileRequest } =
    GraphqlService();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const customParamsRef = useRef<any>({});

  // contexts
  const { functions, t, getTenantsAssociatedWithUser, selectedTenantId } =
    useContext(ContextApp);

  /**
   * Se configura por cada ABM diferente
   */
  const TITLE_CREATE_FORM = `${t('action.create')} ${t(
    'entity.tenant',
  ).toLocaleLowerCase()}`;
  const TITLE_UPDATE_FORM = `${t('action.modify')} ${t(
    'entity.tenant',
  ).toLocaleLowerCase()}`;

  // methods

  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'tenant-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'tenant-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };
  useLayoutEffect(() => {
    renderFilterText();
  }, []);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      variables.current.filter = {};
      const search = JSON.parse(JSON.stringify(params));

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      if (customParamsRef.current && customParamsRef.current.filter) {
        Object.entries(customParamsRef.current.filter).forEach(
          ([key, value]) => {
            if (
              Tools.isDefined(value) &&
              (!Array.isArray(value) || value.length > 0)
            ) {
              variables.current.filter[key] = value;
            }
          },
        );
      }

      variables.current.filter.id?.length === 0 &&
        (variables.current.filter.id = undefined);

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });
      const countPromise = customRequest({
        query: Query.tenantCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.tenants,
        variables: variables.current,
      }).then((data) => {
        if (data.length) {
          return data.map((item: any) => {
            item.targetName = item.target?.name;
            return item;
          });
        }
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      setFormLoading(false);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      openNotification({
        context: 'TenantPage.request.1',
        msj: getErrorMessage(error),
        type: 'error',
      });
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const getTenantPicture = async (tenant_picture_id?: number) => {
    let currentPictureId: number | undefined | null;
    setLoadedPictureId(tenant_picture_id);
    if (!tenant_picture_id) return;
    setLoadingTenantPicture(true);
    try {
      const data = await customRequest({
        query: Query.getTenantPicture,
        variables: { id: tenant_picture_id },
      });
      const url: string = Tools.getUrlOfBase64File({
        mimetype: data.mimetype,
        fileBase64: data.file,
      });
      setLoadedPictureId((oldValue) => {
        currentPictureId = oldValue;
        return tenant_picture_id;
      });
      if (tenant_picture_id === currentPictureId) {
        setUrlFile(url);
        setFileMetaData(data);
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'CompleteTenant.getTenantPicture.1',
        msj: getErrorMessage(error),
      });
    } finally {
      setLoadingTenantPicture(false);
    }
  };

  const getLimitMaxSizeFileSetting = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.LimitMaxSizeFile },
        },
      });
      setMaxSizeFile(Number(data.setting_value));
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'TenantPage.getLimitMaxSizeFileSetting.1',
        msj: getErrorMessage(error),
      });
    }
  };

  const getTenantStates = async () => {
    try {
      const data: ITenantStatus[] = await customRequest({
        query: Query.tenantStates,
        variables: {
          filter: {
            tenant_id: selectedTenantId,
          },
        },
      });
      // TODO: Reemplazar por consulta a backend cuando se creen servicios de esta entidad
      setTenantStatesCombo(data);
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'TenantPage.getTenantStates.1',
        msj: getErrorMessage(error),
      });
    }
  };

  const getTargets = async () => {
    try {
      const response = await customRequest({
        query: Query.targets,
      });
      setTargets(response);
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'tenantPage.targets.1',
        msj: getErrorMessage(error),
      });
    }
  };

  useEffect(() => {
    getTargets();
    getLimitMaxSizeFileSetting();
    getTenantStates();
  }, []);

  const createTenant = async (value: {
    name: string;
    tenant_picture_id?: number;
    tenant_status_id: number;
    tax_type_id?: number;
  }) => {
    setFormLoading(true);
    delete value.tenant_picture_id;
    value.tenant_status_id = +value.tenant_status_id;
    if (value.tax_type_id) value.tax_type_id = +value.tax_type_id;
    try {
      await customFileRequest(
        {
          mutation: Mutation.createTenant,
          variables: {
            input: value,
            file:
              fileMetaData && uploadImage
                ? {
                    filename: fileMetaData?.filename,
                    mimetype: fileMetaData?.type,
                    encoding: 'base64',
                  }
                : undefined,
          },
        },
        fileMetaData && uploadImage
          ? [
              {
                file: fileMetaData,
                path: 'variables.file',
              },
            ]
          : [],
      );
      setCreateModalVisible(false);

      openNotification({
        type: 'success',
        context: 'TenantPage.createTenant.2',
        msj: t('message.createSuccess'),
      });
      getTenantsAssociatedWithUser();

      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.message) {
        setFormLoading(false);
        return openNotification({
          type: 'error',
          context: 'TenantPage.createTenant.3',
          msj: getErrorMessage(error),
        });
      }
    }
    resetImageStates();
    setFormLoading(false);
  };

  const updateTenant = async (value: {
    name: string;
    tenant_picture_id?: number;
    tenant_status_id: number;
    tax_type_id?: number;
  }) => {
    if (!editForm) return;
    setFormLoading(true);
    delete value.tenant_picture_id;
    value.tenant_status_id = +value.tenant_status_id;
    if (value.tax_type_id) value.tax_type_id = +value.tax_type_id;
    try {
      await customFileRequest(
        {
          mutation: Mutation.updateTenant,
          variables: {
            id: editForm.id,
            input: {
              ...value,
              deleteCurrentImage: deleteCurrentImage && !uploadImage,
            },
            file:
              fileMetaData && uploadImage
                ? {
                    filename: fileMetaData?.name,
                    mimetype: fileMetaData?.type,
                    encoding: 'base64',
                  }
                : undefined,
          },
        },
        fileMetaData && uploadImage
          ? [
              {
                file: fileMetaData,
                path: 'variables.file',
              },
            ]
          : [],
      );

      openNotification({
        type: 'success',
        context: 'TenantPage.updateTenant.2',
        msj: t('message.updateSuccess'),
      });
      getTenantsAssociatedWithUser();

      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          context: 'TenantPage.updateTenant.3',
          msj: getErrorMessage(error),
        });
      }
      openNotification({
        type: 'error',
        context: 'TenantPage.updateTenant.3',
        msj: t('error.abm.update'),
      });
    }
    handleUpdateModalVisible(false);
    setEditFormValues(undefined);
    resetImageStates();
    setFormLoading(false);
  };

  const removeConfirmedTenant = async (value: { id: number; name: string }) => {
    try {
      await customRequest({
        mutation: Mutation.unsubscribeTenant,
        variables: {
          tenant_id: value.id,
        },
      });
      openNotification({
        type: 'success',
        context: 'TenantPage.removeConfirmedTenant.2',
        msj: t('message.deleteSuccess'),
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        context: 'TenantPage.removeConfirmedTenant.3',
        msj: getErrorMessage(error),
      });
    }
  };

  const removeTenant = (value: { id: number; name: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>
            {t('message.confirmRemoveTenant', { tenantname: value.name })}
          </div>
        </>
      ),
      cancelText: t('action.cancel'),
      okText: t('action.accept'),
      onOk: () => {
        removeConfirmedTenant(value);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const resetImageStates = () => {
    setErrorFile(() => undefined);
    setFileMetaData(() => undefined);
    setLoadingTenantPicture(() => false);
    setUploadImage(() => false);
    setUrlFile(() => undefined);
    setDeleteCurrentImage(() => false);
  };

  const uploadRequest = (uploadRequestOptions: any) => {
    setLoadingTenantPicture(() => true);
    const { onSuccess, file } = uploadRequestOptions;
    const fileRc = file as RcFile;
    if (fileRc.size > maxSizeFile) {
      setLoadingTenantPicture(() => false);
      setErrorFile(t('error.abm.imageMaxSize'));
    } else {
      setErrorFile(undefined);
      Tools.getBase64WithCallback(file, (fileUrl: string) => {
        setUrlFile(fileUrl);
      });
      setFileMetaData(fileRc);
      if (onSuccess) {
        setLoadingTenantPicture(() => false);
        setUploadImage(true);

        // TODO: Se realiza de esta manera por un bug de antd
        setTimeout(onSuccess, 0);
      }
    }
  };

  const columns = useCallback(
    (editMode?: boolean, createMode?: boolean): ExportableColumn<ITenant>[] => [
      {
        export: true,
        dataIndex: 'id',
        title: t('entity.id'),
        sorter: true,
        render: (_, record) => record.id,
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
        width: 70,
      },
      { ...columnName, hideInSearch: true },
      {
        export: true,
        dataIndex: 'name',
        hideInForm: true,
        hideInTable: true,
        renderFormItem: () => (
          <Input
            allowClear
            placeholder={`${t('action.input.enter')} ${t(
              'entity.name',
            ).toLocaleLowerCase()}`}
          />
        ),
      },
      {
        export: false,
        dataIndex: 'tenant_picture_id',
        title: `${t('entity.image')} ${t('action.optional')}`,
        type: ABM.TYPE_COLUMN.STRING,
        renderFormItem: () => {
          const uploadButton = (
            <Button className="tenant-upload-btn">
              <span className="material-symbols-outlined tenant-upload-icon">
                upload
              </span>
              {t('action.upload')}
            </Button>
          );
          return (
            <>
              {errorFile ? (
                <Alert
                  style={{ marginTop: '14px' }}
                  message={errorFile}
                  type="error"
                  showIcon
                />
              ) : null}
              <Form.Item>
                <Upload
                  fileList={
                    loadingTenantPicture || fileMetaData
                      ? [
                          {
                            status: loadingTenantPicture
                              ? 'uploading'
                              : undefined,
                            name:
                              fileMetaData?.filename ||
                              fileMetaData?.name ||
                              '-',
                            uid: '1',
                            url: urlFile,
                          },
                        ]
                      : undefined
                  }
                  listType="picture"
                  className="avatar-uploader"
                  showUploadList={true}
                  onRemove={() => {
                    setErrorFile(() => undefined);
                    setFileMetaData(() => undefined);
                    setLoadingTenantPicture(() => false);
                    setUploadImage(() => false);
                    setUrlFile(() => undefined);
                    setDeleteCurrentImage(() => true);
                    setEditFormValues((old) => {
                      if (old?.tenant_picture_id)
                        old.tenant_picture_id = undefined;
                      return old;
                    });
                  }}
                  multiple={false}
                  maxCount={1}
                  customRequest={(uploadRequestOptions) => {
                    uploadRequest(uploadRequestOptions);
                  }}
                >
                  {uploadButton}
                </Upload>
              </Form.Item>
            </>
          );
        },
        align: 'left',
        hideInTable: true,
        hideInSearch: true,
        hideInForm: false,
      },
      columnBillingName,
      columnAddress,
      columnTaxType,
      columnTaxNumber,
      {
        export: true,
        dataIndex: 'targetName',
        title: t('entity.segment'),
        renderFormItem: () => (
          <Select
            options={targets.map((target) => ({
              label: target.description,
              value: target.name,
            }))}
            getPopupContainer={(node) => node.parentNode}
            placeholder={`${t('action.input.select')} ${t(
              'entity.segment',
            ).toLocaleLowerCase()}`}
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
            allowClear
          />
        ),
        formItemProps: {
          label: `${t('entity.segment')} ${t('action.optional')}`,
        },
        hideInSearch: true,
        hideInTable: false,
        hideInForm:
          editMode &&
          !Authorization.security(functions, EnumsValues.Functions.IsAdmin),
      },
      {
        export: true,
        dataIndex: 'tenant_status_id',
        title: t('entity.status'),
        render: (_, record) => {
          switch (record.tenant_status_id) {
            case TenantStatus.Activa:
              return (
                <span
                  className="material-symbols-outlined tenant-action-icon"
                  style={{ color: 'green' }}
                >
                  check
                </span>
              );
            case TenantStatus.Inactiva:
              return (
                <span
                  className="material-symbols-outlined tenant-action-icon"
                  style={{ color: 'gray' }}
                >
                  check
                </span>
              );
            case TenantStatus.BajaSolicitada:
              return (
                <span
                  className="material-symbols-outlined tenant-action-icon"
                  style={{ color: 'red' }}
                >
                  close
                </span>
              );
            default:
              return '-';
          }
        },
        formItemProps: {
          rules: [{ required: true, message: t('error.abm.statusRequired') }],
        },
        renderDataExport: (record: ITenant) => {
          return record.tenant_status.name;
        },
        align: 'center',
        renderFormItem: () => (
          <Select
            options={tenantStatesCombo
              .map((state) => ({
                label: t(state.translation_key as never, {
                  defaultValue: state.name,
                }),
                value: editMode || createMode ? state.id.toString() : state.id,
              }))
              .filter((state) =>
                createMode
                  ? state.value.toString() !==
                    TenantStatus.BajaSolicitada.toString()
                  : state,
              )}
            placeholder={`${t('action.input.select')} ${t(
              'entity.status',
            ).toLocaleLowerCase()}`}
            getPopupContainer={(node) => node.parentNode}
            showSearch
            filterOption={(inputValue: string, option: any) =>
              option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) >=
              0
            }
          />
        ),
        hideInSearch: true,
      },
      {
        title: t('entity.op'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.ReadTenantPicture,
            ) && (
              <>
                <Tooltip
                  key="preview_tenant"
                  trigger={tooltipTrigger}
                  title={t('message.previewTenantImage')}
                >
                  <span
                    className="material-symbols-outlined pointer tenant-action-icon"
                    onClick={() => {
                      setPreviewImageTenantId(record.id);
                      getTenantPicture(record.tenant_picture_id);
                    }}
                  >
                    visibility
                  </span>
                </Tooltip>
              </>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.TenantUpdate,
            ) && (
              <>
                <Tooltip
                  key="edit_tenant_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title={`${t('action.modify')} ${t(
                    'entity.tenant',
                  ).toLocaleLowerCase()}`}
                >
                  <span
                    className="material-symbols-outlined pointer tenant-action-icon"
                    onClick={() => {
                      setEditFormValues(record);
                      getTenantPicture(record.tenant_picture_id);
                      setSelectedTaxType(record.tax_type_id);
                      handleUpdateModalVisible(true);
                    }}
                  >
                    edit
                  </span>
                </Tooltip>
              </>
            )}
            {Authorization.security(functions, EnumsValues.Functions.IsAdmin) &&
              record.tenant_status_id !== TenantStatus.BajaSolicitada && (
                <>
                  <Tooltip
                    key="remove_tenant_tooltip"
                    trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                    title={`${t('action.remove')} ${t(
                      'entity.tenant',
                    ).toLocaleLowerCase()}`}
                  >
                    <span
                      className="material-symbols-outlined pointer tenant-action-icon"
                      onClick={() => {
                        removeTenant(record);
                      }}
                    >
                      delete
                    </span>
                  </Tooltip>
                </>
              )}
          </>
        ),
      },
    ],
    [
      dataTable,
      editForm,
      urlFile,
      maxSizeFile,
      loadingTenantPicture,
      errorFile,
      fileMetaData,
      selectedTaxType,
    ],
  );
  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const previewTenantImage = useMemo(() => {
    if (!previewImageTenantId) {
      return null;
    }
    return (
      <Modal
        open
        footer={null}
        width="max-content"
        bodyStyle={{ paddingBottom: 48 }}
        onCancel={() => {
          setPreviewImageTenantId(undefined);
          resetImageStates();
        }}
        title={t('entity.preview')}
        destroyOnClose
      >
        {!urlFile && loadedPictureId != null ? (
          <div
            className="tenant-image-preview"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Image
              src={urlFile ? urlFile : ''}
              alt={t('error.backend.imageNotFound')}
              preview={false}
              className="tenant-image-preview"
              fallback={imageNotFound}
            />
          </div>
        )}
      </Modal>
    );
  }, [previewImageTenantId, dataTable, urlFile, loadingTenantPicture]);

  const [columnsStateMap, setColumnsStateMap] = useState<
    Record<string, ColumnsState>
  >({
    id: {
      show: false,
    },
  });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  return (
    <>
      <ProTable<ITenant>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        actionRef={actionRef}
        rowKey="id"
        className="protable-tenant"
        options={{
          density: false,
          reload: false,
        }}
        size="small"
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
        search={false}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<ITenant>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <ToolbarFilterOrder
            onSearch={handleSearch}
            showButtonNew={false}
            columns={columns()}
            onFinishFilter={(values) => {
              customParamsRef.current.filter = values;
              if (actionRef.current?.reload) {
                actionRef.current.reload(true);
              }
            }}
            filterNames={INPUT_SEARCH_PLACEHOLDER}
          />,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.TenantCreate,
            ) && (
              <Button
                type="primary"
                className="tenant-add-btn"
                onClick={() => {
                  setCreateModalVisible(true);
                }}
                icon={
                  <span className="material-symbols-outlined tenant-add-icon">
                    add
                  </span>
                }
              >
                {t('entity.new')}
              </Button>
            )}
          </>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
          resetImageStates();
          setSelectedTaxType(undefined);
        }}
        values={{
          tenant_status_id: TenantStatus.Activa,
        }}
        modalVisible={createModalVisible}
        onOk={(value) => {
          createTenant(value);
          setSelectedTaxType(undefined);
        }}
        columns={columns(false, true)}
        notIgnoreFalsyValues={true}
        saveFormFooterIcon={{
          reset: <></>,
        }}
        buttonCancel={true}
        buttonReset={false}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
          }}
          columns={columns(true)}
          onOk={(value) => {
            updateTenant(value);
            setSelectedTaxType(undefined);
          }}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(undefined);
            resetImageStates();
            setSelectedTaxType(undefined);
          }}
          notIgnoreFalsyValues={true}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
      {previewTenantImage}
    </>
  );
}
