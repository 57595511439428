import { NavContext } from '@ionic/react';
import { Button } from 'antd';
import { useContext, useLayoutEffect } from 'react';
import { GenericCard } from '../../components/common/GenericCard/GenericCard';
import { ContextApp } from '../../contexts/ContextApp';
import './HomePage.less';
import { AvailableQuotaCard } from '../../components/common/AvailableQuotaCard/AvailableQuotaCard';
import useNotificationCards from '../../hooks/useNotificationCards';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper/FeatureFlagWrapper';
import { EnumsValues } from '../../enums/EnumsValues';
import { useLocation } from 'react-router-dom';
import { tourContext } from '../../contexts/TourContext';
import useFeatureFlagConfig from '../FeatureFlagWrapper/hooks/useFeatureFlagConfig';
import { PageLoading } from '@ant-design/pro-layout';

const HomePage = () => {
  const { t, setMainMenuCollapsed, user, roles } = useContext(ContextApp);

  const { navigate } = useContext(NavContext);
  const { errorCard } = useNotificationCards();
  const location = useLocation();
  const { createTour } = useContext(tourContext);
  useLayoutEffect(() => {
    if (location.state?.runTour === true && location.pathname === '/app/home') {
      setMainMenuCollapsed(false);
      createTour(EnumsValues.TourSections.Menu);
      location.state.runTour = false;
    }
  }, []);

  const { loading: loadingMethodsFeatureFlag } = useFeatureFlagConfig({
    featureFlag: EnumsValues.FeatureFlags.methodsPage,
  });

  const { loading: loadingPacksFeatureFlag } = useFeatureFlagConfig({
    featureFlag: EnumsValues.FeatureFlags.packsPage,
  });

  const loadingFeatureFlags =
    loadingMethodsFeatureFlag || loadingPacksFeatureFlag;

  const isOrganizationUser = () => {
    const [role] = roles;
    if (role) {
      return (
        // Puede un usuario tener mas de un rol? si puede, quiero limitar solo al que tiene un unico rol TenantUser
        roles.length === 1 &&
        role.name === EnumsValues.SystemRolesNames.TenantUser
      );
    }
    return false;
  };

  return (
    <div className="homepage">
      {errorCard}
      <div className="homepage__header--container">
        <div className="homepage__titles--container">
          <p className="homepage__title">
            {t('homePage.title', {
              firstname: user?.firstname,
            })}
          </p>
          <p className="homepage__subtitle">{t('homePage.subtitle')}</p>
        </div>
        <AvailableQuotaCard version="2" />
      </div>
      <GenericCard
        icon={
          <span className="material-symbols-outlined homepage__generic-card--icon">
            flag_circle
          </span>
        }
        title={t('homePage.charge')}
        className="homepage__generic-card--charge"
        onCardClick={() => navigate('/app/receipt')}
        button={
          <Button className="homePage-btn-start">
            {t('homePage.letsStart!')}
            <span className="material-symbols-outlined arrow-start">
              arrow_right_alt
            </span>
          </Button>
        }
        description={t('homePage.chargeDescription')}
        orientation="horizontal"
      />

      {!loadingFeatureFlags ? (
        <div>
          <p className="homepage__other-recomendations">
            {t('homePage.otherRecomendations')}
          </p>
          <div className="homepage__generic-card--container">
            {!isOrganizationUser() && (
              <div style={{ flex: 1 }}>
                <GenericCard
                  icon={
                    <span className="material-symbols-outlined homepage__generic-card--icon">
                      settings
                    </span>
                  }
                  title={t('tenant_setting.generalSetting')}
                  description={t('tenant_setting.descriptionSettingCard')}
                  className="homepage__generic-card--credits homepage__generic-card--recomendation"
                  orientation="vertical"
                  onCardClick={() => navigate('/app/setting/general')}
                />
              </div>
            )}

            <div style={{ flex: 1 }}>
              <GenericCard
                icon={
                  <span className="material-symbols-outlined homepage__generic-card--icon">
                    dvr
                  </span>
                }
                title={t('page.concepts')}
                description={t('Concept.conceptDescriptionCard')}
                className="homepage__generic-card--integration homepage__generic-card--recomendation"
                orientation="vertical"
                onCardClick={() => navigate('/app/administration/concepts')}
              />
            </div>
            <FeatureFlagWrapper
              featureFlag={EnumsValues.FeatureFlags.packsPage}
              loadingComponent={null}
            >
              <GenericCard
                icon={
                  <span className="material-symbols-outlined homepage__generic-card--icon">
                    monetization_on
                  </span>
                }
                title={t('homePage.credits')}
                description={t('homePage.creditsDescription')}
                className="homepage__generic-card--credits homepage__generic-card--recomendation"
                orientation="vertical"
                onCardClick={() => navigate('/app/packs')}
              />
            </FeatureFlagWrapper>
          </div>
        </div>
      ) : (
        <PageLoading></PageLoading>
      )}
    </div>
  );
};
export default HomePage;
