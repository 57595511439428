import { useContext } from 'react';
import { DataType } from '../ModalViewReceipt';
import { ContextApp } from '../../../../../contexts/ContextApp';
import { IViewModalReceiptState } from '../../../Receipt';
import MomentTimezoneService from '../../../../../services/moment-timezone/MomentTimezoneService';
import moment from 'moment';

const useGetGeneralTabContent = (state: IViewModalReceiptState) => {
  const { t } = useContext(ContextApp);
  const { getDate } = MomentTimezoneService();

  const generalTabContent1: DataType[] = [
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.receiptNumber'),
      value: state.data?.receipt_number,
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.type'),
      value: state.data?.receipt_type?.name,
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.letter'),
      value: state.data?.receipt_letter?.name,
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.receiptDate'),
      value: getDate({ element: moment(state.data?.receipt_date).toDate() }),
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.dueDate'),
      value: getDate({ element: moment(state.data?.due_date).toDate() }),
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.purchaseOrder'),
      value: state.data?.purchase_order,
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.relatedDocumentName'),
      value: state.data?.related_document_name,
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.relatedDocumentNumber'),
      value: state.data?.related_document_number,
    },
  ];

  const generalTabContent2: DataType[] = [
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.senderName'),
      value: state.data?.sender?.name,
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.senderTaxType'),
      value: state.data?.sender?.tax_type?.name,
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.senderTaxNumber'),
      value: state.data?.sender?.tax_number?.toString(),
    },
    {
      label: t('ReceiptTray.modalViewReceipt.generalTab.senderTaxCondition'),
      value: state.data?.sender?.tax_condition,
    },
  ];

  const generalTabContent3: DataType[] = [
    {
      label: t(
        'ReceiptTray.modalViewReceipt.generalTab.receiptVerificationCode',
      ),
      value: state.data?.receipt_verification_code,
    },
    {
      label: t(
        'ReceiptTray.modalViewReceipt.generalTab.receiptVerificationDueDate',
      ),
      value: getDate({
        element: moment(state.data?.receipt_verification_due_date).toDate(),
      }),
    },
  ];

  return {
    generalTabContent1,
    generalTabContent2,
    generalTabContent3,
  };
};
export default useGetGeneralTabContent;
