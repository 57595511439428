import { Collapse } from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import { CustomMessage } from '../../hooks';
import { IPage, IWord } from '../../interfaces/Extraction';
import './ReviewReceiptCanvas.less';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { ILog } from '../../interfaces/Log';
import ReactMarkdown from 'react-markdown';
import breaks from 'remark-breaks';
import { notificationContext } from '../../contexts/NotificationContext';

import ReceiptValidateButton from './ReceiptValidateButton';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFViewerProps {
  file: string;
  pages: IPage[];
  lastLog: ILog | undefined;
  onCanvasItemClick: (
    content: string,
    shiftKey: boolean,
    element: IWord | null,
    ReviewReceipt: number,
  ) => void;
  expandedLogs: boolean;
  setExpandedLogs: Dispatch<SetStateAction<boolean>>;
  handleValidateButton?: () => void;
  shouldValidateButtonBeVisible: boolean;
}

const canvasItemInnerSpacing = EnumsValues.ConstNumbers.one;

export default function ReviewReceiptCanvas(props: PDFViewerProps) {
  const {
    file,
    pages,
    onCanvasItemClick,
    lastLog,
    expandedLogs,
    setExpandedLogs,
    handleValidateButton,
    shouldValidateButtonBeVisible,
  } = props;
  const selectionItemDiv = useRef<HTMLDivElement>(null);
  const totalPageNro = pages.length;
  const [actualPageNro, setActualPageNro] = useState<{
    key: number;
    value: number;
  }>({
    key: EnumsValues.ConstNumbers.one,
    value: EnumsValues.ConstNumbers.one,
  });
  const [showPDf, setShowPDf] = useState<boolean>(false);
  const { t } = useContext(ContextApp);
  const VIEWPORT_SCALE = 1.25;
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const [pdfProccessed, setPdfProccessed] = useState(false);
  const selectedCanvasItemRef = useRef<HTMLDivElement>();

  const getPdfInfoByPage = async (pageNumber: number) => {
    const pdf = pdfjs.getDocument(file);

    const data = await pdf.promise;

    return data.getPage(pageNumber);
  };

  const onPdfLoadSuccess = async () => {
    setPdfProccessed(true);
  };

  const drawItemsOnCanvas = useCallback(
    async (pageNumber: number, pagesInfo: IPage[]) => {
      try {
        if (!selectionItemDiv.current) {
          return;
        }

        const page = await getPdfInfoByPage(pageNumber);

        const extractorPage = pagesInfo.find(
          (item) => item.pageNumber === pageNumber,
        );

        if (!extractorPage) return;

        const viewport = page.getViewport({ scale: VIEWPORT_SCALE });
        if (viewport) setShowPDf(true);
        //Necesario doble control de selectionItemDiv.current por si salimos de la pantalla antes de que termine de cargar el documento https://redmine.bombieri.com.ar/issues/32653
        if (!selectionItemDiv.current) {
          return;
        }
        selectionItemDiv.current.style.width = viewport.width + 'px';
        selectionItemDiv.current.style.height = viewport.height + 'px';
        const pageScale =
          viewport.height /
          (extractorPage.height * EnumsValues.FileUnitConvertion.InchToPx);

        extractorPage.words.forEach((element) => {
          const markDiv = document.createElement('div');
          markDiv.style.top =
            element.polygon[0].y *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale -
            canvasItemInnerSpacing +
            'px';
          markDiv.style.left =
            element.polygon[0].x *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale -
            canvasItemInnerSpacing +
            'px';
          markDiv.style.width =
            (element.polygon[1].x - element.polygon[0].x) *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale +
            2 * canvasItemInnerSpacing +
            'px';
          markDiv.style.height =
            (element.polygon[2].y - element.polygon[1].y) *
              EnumsValues.FileUnitConvertion.InchToPx *
              pageScale +
            2 * canvasItemInnerSpacing +
            'px';
          markDiv.classList.add('pdf-canvas-item');
          markDiv.setAttribute('json-config', JSON.stringify(element));
          markDiv.addEventListener('click', (event) => {
            onCanvasItemClick(
              element.content,
              event.shiftKey,
              element,
              pageNumber,
            );
            if (selectedCanvasItemRef.current && event.shiftKey === false) {
              const divsWithClass = document.querySelectorAll(
                '.pdf-canvas-item-active',
              );

              divsWithClass.forEach((div) => {
                div.classList.remove('pdf-canvas-item-active');
              });
            }
            selectedCanvasItemRef.current = markDiv;
            markDiv.classList.add('pdf-canvas-item-active');
          });
          selectionItemDiv.current?.appendChild(markDiv);
        });
      } catch (error) {
        return openNotification({
          type: 'error',
          msj: getErrorMessage(error),
          context: 'ReviewReceiptCanvas.onDocumentLoadSuccess.1',
        });
      }
    },
    [pages],
  );

  const clearItemsOnCanvas = () => {
    if (!selectionItemDiv.current) {
      return;
    }
    while (selectionItemDiv.current.firstChild) {
      selectionItemDiv.current.removeChild(selectionItemDiv.current.firstChild);
    }
    selectedCanvasItemRef.current = undefined;
  };

  useEffect(() => {
    if (pdfProccessed) {
      drawItemsOnCanvas(actualPageNro.value, pages);
    }
    return () => {
      clearItemsOnCanvas();
    };
  }, [pdfProccessed, actualPageNro, pages]);

  const changePage = ({
    pages,
    currentPageNumber,
    action,
  }: {
    pages: IPage[];
    currentPageNumber: number;
    action: 'left' | 'right';
  }) => {
    const pageIndex = pages.findIndex(
      (item) => item.pageNumber === currentPageNumber,
    );

    const newPage = {
      key:
        pageIndex +
        EnumsValues.ConstNumbers.one +
        (action === 'left'
          ? EnumsValues.ConstNumbers.negativeOne
          : EnumsValues.ConstNumbers.one),
      value:
        pages[
          pageIndex +
            (action === 'left'
              ? EnumsValues.ConstNumbers.negativeOne
              : EnumsValues.ConstNumbers.one)
        ].pageNumber,
    };
    setActualPageNro(newPage);
  };

  const pageIndex = pages.findIndex(
    (item) => item.pageNumber === actualPageNro.value,
  );

  const hasPagesToLeft = pageIndex > EnumsValues.ConstNumbers.zero;

  const hasPagesToRight =
    pageIndex !== EnumsValues.ConstNumbers.negativeOne &&
    pageIndex < pages.length - EnumsValues.ConstNumbers.one;

  const hasErrorLogs = useMemo(() => {
    return !!(lastLog && lastLog.description && lastLog.description.length > 0);
  }, [lastLog]);

  return (
    <>
      {showPDf && (
        <div className="canvas-btnGroup">
          <Collapse
            className={`collapse-review-logs ${
              !hasErrorLogs ? 'collapse-review-logs--disabled' : ''
            }`}
            bordered={false}
            activeKey={expandedLogs === true ? ['7'] : []}
            onChange={() => {
              expandedLogs ? setExpandedLogs(false) : setExpandedLogs(true);
            }}
          >
            <CollapsePanel
              aria-expanded="false"
              header={
                <div className="collapse-log-alert-container">
                  <div className="collapse-log-alert-container__left">
                    <span className="collapse-log-alert-container__title">
                      {t('entity.errorsInbox')}
                    </span>
                    <div className="collapse-log-alert">
                      {hasErrorLogs ? (
                        <>
                          <span className="material-symbols-outlined review-alert-icon">
                            error
                          </span>{' '}
                          {t('label.missingData')}
                        </>
                      ) : (
                        <>
                          <span className="material-symbols-outlined review-success-icon">
                            check_circle
                          </span>{' '}
                          {t('label.noErrors')}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              }
              key="7"
              extra={
                <div onClick={(e) => e.stopPropagation()}>
                  <ReceiptValidateButton
                    onButtonClick={handleValidateButton}
                    hasErrorLogs={hasErrorLogs}
                    shouldBeVisible={shouldValidateButtonBeVisible}
                  />
                </div>
              }
            >
              <div>
                <ReactMarkdown
                  className="scroll-collapse"
                  children={` ${lastLog?.description?.replaceAll(
                    /{{(.+?)}}/g,
                    (_, key) => t(key as never),
                  )}`}
                  remarkPlugins={[breaks]}
                />
              </div>
            </CollapsePanel>
          </Collapse>
        </div>
      )}
      <div className="canvas-nextPage-btn">
        <div className="btn-group-pageinfo">
          <button
            onClick={() =>
              changePage({
                pages,
                currentPageNumber: actualPageNro.value,
                action: 'left',
              })
            }
            disabled={!hasPagesToLeft}
            style={{ background: 'transparent' }}
            className="btn-group-pageinfo__button-prev"
          >
            <span className="material-symbols-outlined receipt-page-icon">
              navigate_before
            </span>
          </button>

          <div className="btn-group-pageinfo__text">
            {actualPageNro.key} / {totalPageNro}
          </div>

          <button
            onClick={() =>
              changePage({
                pages,
                currentPageNumber: actualPageNro.value,
                action: 'right',
              })
            }
            disabled={!hasPagesToRight}
            style={{ background: 'transparent' }}
            className="btn-group-pageinfo__button-next"
          >
            <span className="material-symbols-outlined receipt-page-icon">
              navigate_next
            </span>
          </button>
        </div>
      </div>
      <div className="canvas-pdf">
        <Document
          className="receipt-pdf"
          file={file}
          onLoadSuccess={onPdfLoadSuccess}
        >
          <Page
            scale={VIEWPORT_SCALE}
            pageNumber={actualPageNro.value}
            renderAnnotationLayer={false}
          />
        </Document>
        <div
          ref={selectionItemDiv}
          style={{ backgroundColor: 'transparent', position: 'relative' }}
        ></div>
      </div>
    </>
  );
}
