import ProTable, { ActionType, ColumnsState } from '@ant-design/pro-table';
import { Button, Input, Modal, Tooltip } from 'antd';
import { SaveForm } from '../../components/common/ABM';
import {
  useCallback,
  useContext,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { ExportableColumn } from '../../shared/Exporter';
import { SorterResult } from 'antd/lib/table/interface';
import GraphqlService from '../../services/graphql/GraphqlService';
import { Authorization, Tools } from '../../shared';
import { isPlatform } from '@ionic/react';
import { CustomMessage } from '../../hooks';
import { ContextApp } from '../../contexts/ContextApp';
import { EnumsValues } from '../../enums/EnumsValues';
import useProTableForMobile from '../../hooks/useProTableForMobile';
import { ParamsType } from '@ant-design/pro-provider';
import { getPaginationArgs } from '../../shared/getPaginationArgs';
import { IProduct } from '../../interfaces/Product';
import { useImportProducts } from '../../hooks/useImportProducts';
import './Product.less';
import { notificationContext } from '../../contexts/NotificationContext';
import ToolbarFilterOrder from '../../components/common/ToolbarFilterOrder/ToolbarFilterOrder';
/**
 * Configure manualmente los campos de filtrado
 */
const LIST_FILTER = ['description', 'code'];
const LIST_SORTER = ['name', 'id', 'description', 'code'];

export default function Product() {
  // props

  // states
  const [searchText, setSearchText] = useState('');
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [editForm, setEditFormValues] =
    useState<IProduct | undefined>(undefined);
  const [formLoading, setFormLoading] = useState(false);
  const [sorter, setSorter] = useState<string>('');
  const [dataTable, setDataTable] = useState<IProduct[]>([]);

  // services and hooks
  const { mobileOnSizeChangeProTable, showComponent } = useProTableForMobile({
    layout: 'horizontal',
  });
  const { openNotification } = useContext(notificationContext);

  const { Query, Mutation, customRequest } = GraphqlService();
  const { getErrorMessage } = CustomMessage();

  // refs
  const actionRef = useRef<ActionType>();
  const variables = useRef<any>({});
  const customParamsRef = useRef<any>({});

  const { importProductsButton, importProductsSaveform } = useImportProducts({
    actionRef,
  });

  // contexts
  const { functions, t, selectedTenantId } = useContext(ContextApp);

  /**
   * Se configura por cada ABM diferente
   */
  const TITLE_CREATE_FORM = `${t('action.create')}`;
  const TITLE_UPDATE_FORM = `${t('action.modify')}`;

  // methods

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (actionRef.current?.reloadAndRest) {
      actionRef.current.reloadAndRest();
    }
  };

  const renderFilterText = () => {
    const searchProTableCollection = document.getElementsByClassName(
      'product-pro-table-search',
    );

    if (!searchProTableCollection.length) {
      return;
    }

    const searchProTable = searchProTableCollection[0];

    const filterTextDivClassname = 'product-pro-table-search-filter-text';

    const div = document.createElement('div');
    div.innerText = t('action.filters');
    div.className = filterTextDivClassname;

    let filterTextFound = false;

    for (
      let index = EnumsValues.ConstNumbers.zero;
      index < searchProTable.children.length;
      index++
    ) {
      const element = searchProTable.children[index];
      if (element.className === filterTextDivClassname) {
        filterTextFound = true;
        break;
      }
    }

    if (!filterTextFound) searchProTable.prepend(div);
  };
  useLayoutEffect(() => {
    renderFilterText();
  }, []);

  const request = async (
    params: ParamsType & {
      pageSize?: number;
      current?: number;
      keyword?: string;
    },
  ) => {
    try {
      delete variables.current.filter;
      delete variables.current.orderBy;
      variables.current = {};
      variables.current.filter = {};
      const search = JSON.parse(JSON.stringify(params));

      if (searchText) {
        variables.current.searchText = searchText;
      } else {
        delete variables.current.searchText;
      }

      if (customParamsRef.current && customParamsRef.current.filter) {
        Object.entries(customParamsRef.current.filter).forEach(
          ([key, value]) => {
            if (
              Tools.isDefined(value) &&
              (!Array.isArray(value) || value.length > 0)
            ) {
              variables.current.filter[key] = value;
            }
          },
        );
      }

      variables.current.filter.id?.length === 0 &&
        (variables.current.filter.id = undefined);

      variables.current.filter.tenant_id = selectedTenantId;

      LIST_SORTER.forEach((element) => {
        try {
          if (search.sorter[element]) {
            if (!variables.current.orderBy) {
              variables.current.orderBy = {};
            }
            variables.current.orderBy.direction =
              Tools.getTypeOrderByTableSortParam(search.sorter[element]);
            variables.current.orderBy.field = element;
          }
        } catch (error) {
          // este error esta contemplado porque seguro el filtro que busca no se encuentra
        }
      });
      const countPromise = customRequest({
        query: Query.productCount,
        variables: variables.current,
      }).then((data: { count: number }) => data.count);

      const { skip, take } = getPaginationArgs(
        params.pageSize || 20,
        params.current,
      );

      variables.current.skip = skip;
      variables.current.take = take;

      const dataPromise = customRequest({
        query: Query.products,
        variables: variables.current,
      }).then((data) => {
        if (data.length) {
          return data;
        }
      });

      const [total, data] = await Promise.all([countPromise, dataPromise]);
      setDataTable(data);
      setFormLoading(false);
      return {
        current: params.current,
        data,
        pageSize: params.pageSize,
        success: true,
        total,
      };
    } catch (error) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'Product.request.1',
      });
      return {
        current: params.current,
        data: [],
        pageSize: params.pageSize,
        success: false,
        total: 0,
      };
    }
  };

  const createProduct = async (value: { name: string }) => {
    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.createProduct,
        variables: {
          input: { ...value, tenant_id: selectedTenantId },
        },
      });
      setCreateModalVisible(false);

      openNotification({
        type: 'success',
        context: 'Product.createProduct.1',
        msj: t('message.createSuccess'),
      });

      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      if (error.message) {
        setFormLoading(false);
        return openNotification({
          type: 'error',
          context: 'Product.createProduct.3',
          msj: getErrorMessage(error),
        });
      }
    }
    setFormLoading(false);
  };

  const updateProduct = async (value: { name: string }) => {
    if (!editForm) return;
    setFormLoading(true);
    try {
      await customRequest({
        mutation: Mutation.updateProduct,
        variables: {
          input: {
            ...value,
            id: editForm.id,
          },
        },
      });
      openNotification({
        type: 'success',
        context: 'Product.updateProduct.2',
        msj: t('message.updateSuccess'),
      });

      handleUpdateModalVisible(false);
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      setFormLoading(false);
      if (error.status_code && error.message) {
        return openNotification({
          type: 'error',
          msj: getErrorMessage(error),
          context: 'Product.updateProduct.3',
        });
      }
      openNotification({
        type: 'error',
        context: 'Product.updateProduct.4',
        msj: t('error.abm.update'),
      });
    }
    handleUpdateModalVisible(false);
    setEditFormValues(undefined);
    setFormLoading(false);
  };

  const deleteConfirmedProduct = async (value: { id: number }) => {
    try {
      await customRequest({
        mutation: Mutation.deleteProduct,
        variables: {
          id: value.id,
        },
      });
      openNotification({
        type: 'success',
        context: 'Product.deleteConfirmedProduct.2',
        msj: t('message.deleteSuccess'),
      });
      if (actionRef.current) {
        actionRef.current.reload();
      }
    } catch (error: any) {
      openNotification({
        type: 'error',
        msj: getErrorMessage(error),
        context: 'Product.deleteConfirmedProduct.3',
      });
    }
  };

  const deleteProduct = (value: { id: number; description: string }) => {
    Modal.confirm({
      content: (
        <>
          <div>
            {t('message.confirmRemoveProduct', {
              productdescription: value.description,
            })}
          </div>
        </>
      ),
      cancelText: t('action.cancel'),
      okText: t('action.accept'),
      onOk: () => {
        deleteConfirmedProduct(value);
      },
      okButtonProps: { className: 'save-btn' },
    });
  };

  const columns = useCallback(
    (
      _editMode?: boolean,
      _createMode?: boolean,
    ): ExportableColumn<IProduct>[] => [
      {
        export: true,
        dataIndex: 'id',
        title: t('entity.id'),
        sorter: true,
        render: (_, record) => record.id,
        align: 'right',
        hideInForm: true,
        hideInSearch: true,
        width: 70,
      },
      {
        export: true,
        dataIndex: 'code',
        title: t('entity.code'),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.codeRequired'),
            },
          ],
        },
        render: (_: any, record) => record.code,
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.productCode',
            ).toLocaleLowerCase()}`}
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'code',
        title: t('entity.code'),
        renderFormItem: () => (
          <Input
            allowClear
            placeholder={`${t('action.input.enter')} ${t(
              'entity.code',
            ).toLocaleLowerCase()}`}
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        export: true,
        dataIndex: 'description',
        title: t('entity.name'),
        formItemProps: {
          rules: [
            {
              required: true,
              message: t('error.abm.nameRequired'),
            },
          ],
        },
        render: (_: any, record) => record.description || '-',
        renderFormItem: () => (
          <Input
            placeholder={`${t('action.input.enter')} ${t(
              'entity.productName',
            ).toLocaleLowerCase()}`}
          />
        ),
        align: 'left',
        sorter: true,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: false,
      },
      {
        export: true,
        dataIndex: 'description',
        title: t('entity.name'),
        renderFormItem: () => (
          <Input
            allowClear
            placeholder={`${t('action.input.enter')} ${t(
              'entity.name',
            ).toLocaleLowerCase()}`}
          />
        ),
        hideInTable: true,
        hideInSearch: false,
        hideInForm: true,
      },
      {
        title: t('entity.op'),
        dataIndex: 'option',
        valueType: 'option',
        fixed: 'right',
        width: 100,
        export: false,
        hideInTable: false,
        hideInSearch: true,
        hideInForm: true,
        render: (_, record) => (
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.UpdateProduct,
            ) && (
              <>
                <Tooltip
                  key="edit_product_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title={`${t('action.modify')} ${t(
                    'entity.product',
                  ).toLocaleLowerCase()}`}
                >
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    type="text"
                    icon={
                      <span className="material-symbols-outlined product-action-icon">
                        edit
                      </span>
                    }
                    className="pointer product-action-btn"
                    onClick={() => {
                      setEditFormValues(record);
                      handleUpdateModalVisible(true);
                    }}
                  />
                </Tooltip>
              </>
            )}
            {Authorization.security(
              functions,
              EnumsValues.Functions.DeleteProduct,
            ) && (
              <>
                <Tooltip
                  key="remove_product_tooltip"
                  trigger={isPlatform('desktop') ? 'hover' : ' focus'}
                  title={`${t('action.remove')} ${t(
                    'entity.product',
                  ).toLocaleLowerCase()}`}
                >
                  <Button
                    style={{ margin: 0, padding: 0 }}
                    disabled={record.isInUse === true}
                    type="text"
                    icon={
                      <span className="material-symbols-outlined product-action-icon">
                        delete
                      </span>
                    }
                    className="pointer product-action-btn"
                    onClick={() => {
                      deleteProduct(record);
                    }}
                  />
                </Tooltip>
              </>
            )}
          </>
        ),
      },
    ],
    [dataTable, editForm],
  );

  let LIST_FILTER_NAMES = columns()
    // eslint-disable-next-line array-callback-return
    .filter((value) => {
      if (
        LIST_FILTER.find(
          (element) =>
            element === value.dataIndex && value.hideInTable === false,
        )
      ) {
        return value.title;
      }
    })
    .map((element) => {
      return element.title;
    });

  const [columnsStateMap, setColumnsStateMap] = useState<
    Record<string, ColumnsState>
  >({
    id: {
      show: false,
    },
  });

  const INPUT_SEARCH_PLACEHOLDER = LIST_FILTER_NAMES.join(', ');

  return (
    <>
      <ProTable<IProduct>
        onSizeChange={mobileOnSizeChangeProTable}
        components={{
          table: showComponent(),
        }}
        actionRef={actionRef}
        rowKey="id"
        size="small"
        options={{
          density: false,
          reload: false,
        }}
        className="protable-product"
        columnsState={{
          value: columnsStateMap,
          onChange: setColumnsStateMap,
        }}
        search={false}
        onChange={(_, _filter, _sorter) => {
          const sorterResult = _sorter as SorterResult<IProduct>;
          if (sorterResult.field) {
            setSorter(`${sorterResult.field}_${sorterResult.order}`);
          }
        }}
        onReset={() => {
          setSearchText('');
        }}
        params={{
          sorter,
        }}
        toolBarRender={() => [
          <ToolbarFilterOrder
            onSearch={handleSearch}
            showButtonNew={false}
            columns={columns()}
            onFinishFilter={(values) => {
              customParamsRef.current.filter = values;
              if (actionRef.current?.reload) {
                actionRef.current.reload(true);
              }
            }}
            filterNames={INPUT_SEARCH_PLACEHOLDER}
          />,
          <>
            {Authorization.security(
              functions,
              EnumsValues.Functions.CreateProduct,
            ) && (
              <>
                {importProductsButton}
                <Button
                  type="primary"
                  className="product-add-btn"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                  icon={
                    <span className="material-symbols-outlined product-add-icon">
                      add
                    </span>
                  }
                >
                  {t('entity.new')}
                </Button>
              </>
            )}
          </>,
        ]}
        /**
         * @description este metodo debe poder ejecutar siempre la consulta al backend
         */
        request={async (params, sorter, filter) =>
          request({ ...params, sorter, filter })
        }
        columns={columns()}
      />
      {importProductsSaveform}
      <SaveForm
        loading={formLoading}
        title={TITLE_CREATE_FORM}
        onCancel={() => {
          setCreateModalVisible(false);
        }}
        modalVisible={createModalVisible}
        onOk={(value) => {
          createProduct(value);
        }}
        columns={columns(false, true)}
        notIgnoreFalsyValues={true}
        saveFormFooterIcon={{
          reset: <></>,
        }}
        buttonCancel={true}
        buttonReset={false}
      />
      {editForm && (
        <SaveForm
          loading={formLoading}
          title={TITLE_UPDATE_FORM}
          modalVisible={updateModalVisible}
          values={{
            ...editForm,
          }}
          columns={columns(true)}
          onOk={(value) => {
            updateProduct(value);
          }}
          onCancel={() => {
            handleUpdateModalVisible(false);
            setEditFormValues(undefined);
          }}
          notIgnoreFalsyValues={true}
          saveFormFooterIcon={{
            reset: <></>,
          }}
          buttonCancel={true}
          buttonReset={false}
        />
      )}
    </>
  );
}
