import { useContext, useEffect, useState, useCallback } from 'react';
import { GenericCard } from '../../components/common/GenericCard/GenericCard';
import { ContextApp } from '../../contexts/ContextApp';
import { CardsContainer } from '../../components/common/CardsContainer/CardsContainer';
import './PacksPage.less';
import { IAppSetting } from '../../interfaces';
import GraphqlService from '../../services/graphql/GraphqlService';
import { EnumsValues, TenantStatus } from '../../enums/EnumsValues';
import { IPack } from '../../interfaces/Pack';
import { Mercadopago } from '../../components/common/Mercadopago/Mercadopago';
import { IOrder } from '../../interfaces/Order';
import { Button, Divider } from 'antd';
import { useLocation } from 'react-router';
import { Tools } from '../../shared';
import useCurrentTenantPoll from '../../hooks/useCurrentTenantPoll';
import { ContextWizard } from '../../contexts/ContextWizard';
import { ITenant } from '../../interfaces/Tenant';
import { CustomMessage } from '../../hooks';
import { AlertCard } from '../../components/common/AlertCard/AlertCard';
import { NavContext } from '@ionic/react';
import useNotificationCards from '../../hooks/useNotificationCards';
import { FeatureFlagWrapper } from '../FeatureFlagWrapper/FeatureFlagWrapper';
import { notificationContext } from '../../contexts/NotificationContext';
export const PacksPage = () => {
  const location = useLocation();
  const {
    notifications,
    tenantsAssociatedToUser,
    getTenantsAssociatedWithUser,
    t,
    selectedTenantId,
    checkAuth,
  } = useContext(ContextApp);
  const { checkPackPurchaseStep } = useContext(ContextWizard);
  const { customRequest, Query, Mutation } = GraphqlService();
  const [contactLink, setContactLink] = useState('');
  const [packs, setPacks] = useState<IPack[]>([]);
  const [instanceMercadoPago, setInstanceMercadoPago] = useState<any>();
  const [preferenceId, setPreferenceId] = useState<string>();
  const [tenant, setTenant] = useState<ITenant>();

  const isWizardPage = location.pathname.includes('wizard');
  const statusApproved = location.search.includes('status=approved');
  const statusRejected = location.search.includes('status=rejected');
  const { polling, startPolling, stopPolling } = useCurrentTenantPoll({});
  const [buttonLoading, setButtonLoading] = useState<number>();
  const { openNotification } = useContext(notificationContext);
  const { getErrorMessage } = CustomMessage();
  const { navigate } = useContext(NavContext);
  const { errorCard } = useNotificationCards();

  const getPacks = async (tenant_id: number) => {
    try {
      const data: IPack[] = await customRequest({
        query: Query.packs,
        variables: {
          filter: { tenant_id },
          orderBy: {
            field: 'price',
            direction: 'asc',
          },
        },
      });
      setPacks(data);
    } catch (error) {
      //intentional
    }
  };

  const setTenantStatusActivo = async (tenant_id: number) => {
    try {
      await customRequest({
        query: Mutation.setTenantStatusActivo,
        variables: {
          tenant_id,
        },
      });
    } catch (error) {
      openNotification({
        type: 'error',
        context: 'IntegrationPage.setTenantStatusActivo.1',
        msj: getErrorMessage(error),
      });
    }
  };

  const onOmit = useCallback(async () => {
    if (selectedTenantId) {
      if (
        tenant?.receipts_quota_tenant?.receipts_quota &&
        tenant.receipts_quota_tenant.receipts_quota > 0
      ) {
        await setTenantStatusActivo(selectedTenantId);
        checkAuth(selectedTenantId);
        navigate('/');
      }
    }
  }, [selectedTenantId, tenant]);
  const createOrder = useCallback(
    async ({ id }: IPack) => {
      setButtonLoading(id);
      try {
        const order: IOrder = await customRequest({
          query: Mutation.createOrder,
          variables: {
            input: {
              tenant_id: selectedTenantId,
              tenant_name: tenant?.name || '',
              pack_id: id,
              back_url_success: `${isWizardPage ? 'wizard' : 'app'}/packs`,
              back_url_pending: `${isWizardPage ? 'wizard' : 'app'}/packs`,
              back_url_failure: `${isWizardPage ? 'wizard' : 'app'}/packs`,
            },
          },
        });
        setPreferenceId(order.preference_id);
      } catch (error) {
        openNotification({
          type: 'error',
          context: 'PacksPage.createOrder.1',
          msj: getErrorMessage(error),
        });
      } finally {
        setButtonLoading(undefined);
      }
    },
    [selectedTenantId, tenant],
  );

  useEffect(() => {
    if (tenantsAssociatedToUser) {
      setTenant(
        tenantsAssociatedToUser.find(
          (tenant) => tenant.id === selectedTenantId,
        ),
      );
    }
  }, [tenantsAssociatedToUser]);

  useEffect(() => {
    if (preferenceId && instanceMercadoPago) {
      instanceMercadoPago.open();
    }
  }, [preferenceId, instanceMercadoPago]);

  useEffect(() => {
    selectedTenantId && getPacks(selectedTenantId);
    getTenantsAssociatedWithUser();
  }, [selectedTenantId]);

  useEffect(() => {
    if (
      location.search.includes('status=approved') &&
      (notifications?.thereIsNotQuota || notifications?.quotaIsExpired)
    ) {
      startPolling();
    }

    return () => {
      stopPolling();
    };
  }, [notifications?.thereIsNotQuota, notifications?.quotaIsExpired]);

  useEffect(() => {
    if (
      polling &&
      !(notifications?.thereIsNotQuota || notifications?.quotaIsExpired)
    ) {
      stopPolling();
    }
  }, [notifications?.thereIsNotQuota, notifications?.quotaIsExpired, polling]);

  const getContactLink = async () => {
    try {
      const data: IAppSetting = await customRequest({
        query: Query.getAppSettingByKey,
        variables: {
          input: { key: EnumsValues.SettingNames.ContactLinkOnPackPage },
        },
      });
      setContactLink(data.setting_value);
    } catch (error) {
      //intentional
    }
  };

  useEffect(() => {
    getContactLink();
  }, []);

  const cardHeaderJsx = (value: IPack) => (
    <div className="card-note has-discount">
      <div>{t('PacksPage.sale')}</div>
      {!!value.discount && value.discount !== 0 && (
        <div>{value.discount}% OFF</div>
      )}
    </div>
  );

  const cardBodyJsx = (value: IPack) => (
    <>
      <div className="CardToSelect--pack">{value.name}</div>
      <Divider className="CardToSelectDivider"></Divider>
      <p className="cardToSelect--include">{t('PacksPage.include')}: </p>

      <div className="CardToSelect__flex">
        <span className="material-symbols-outlined packsPage-check-icon">
          check
        </span>
        <p className="CardToSelect__flex--quantity">
          {value.receipt_number} {t('PacksPage.receipts')}
        </p>
      </div>
      <div className="CardToSelect__flex">
        <span className="material-symbols-outlined packsPage-check-icon">
          check
        </span>
        <p className="CardToSelect__flex--days">
          {t('PacksPage.validFor')} {value.validity_days} {t('PacksPage.days')}
        </p>
      </div>
      <div className="CardToSelect--price">
        {Tools.formatCurrency(value.price)}
      </div>
    </>
  );

  const isValid = async (tenant_id: number | undefined) => {
    const isValid: true | undefined = await checkPackPurchaseStep(tenant_id);
    return isValid;
  };

  useEffect(() => {
    if (selectedTenantId) {
      isValid(selectedTenantId);
    }
  }, [selectedTenantId]);

  return (
    <FeatureFlagWrapper featureFlag={EnumsValues.FeatureFlags.packsPage}>
      <div className="PacksPage-container">
        {!isWizardPage && errorCard}
        {statusApproved && (
          <GenericCard
            icon={
              <span className="material-symbols-outlined packsPage-approve-icon">
                check_circle
              </span>
            }
            title={t('PacksPage.SuccessfulPurchase')}
            description={t('PacksPage.creditsAvailable')}
          />
        )}
        {statusRejected && (
          <GenericCard
            icon={
              <span className="material-symbols-outlined packsPage-cancel-icon">
                cancel
              </span>
            }
            title={t('PacksPage.RejectedPurchase')}
            description={t('PacksPage.PurchaseProblem')}
          />
        )}
        {window.location.href.indexOf('wizard') > -1 ? (
          <AlertCard
            icon={
              <span className="material-symbols-outlined packsPage-warning-icon">
                monetization_on
              </span>
            }
            title={t('PacksPage.aboutCredits')}
            description={t('PacksPage.creditsInformation')}
            subDescription={t('PacksPage.creditsBuyInformation')}
            button={
              <Button type="default" onClick={() => onOmit()}>
                {statusApproved
                  ? t('action.continue')
                  : t('PacksPage.omitStep')}
              </Button>
            }
          />
        ) : null}
        <GenericCard
          icon={
            <span
              className="material-symbols-outlined packsPage-support-icon"
              style={{ fontSize: '44px', color: '#03729F' }}
            >
              support_agent
            </span>
          }
          title={t('PacksPage.contactInformation')}
          description={t('PacksPage.contactSalesConsultant')}
          button={
            <Button type="default" onClick={() => window.open(contactLink)}>
              {t('PacksPage.contact')}
            </Button>
          }
          className="PacksPage-message-container"
        />
        <CardsContainer<IPack>
          data={packs}
          information={{
            title: isWizardPage
              ? t('PacksPage.packInformationWizardTitle')
              : t('PacksPage.packInformationTitle'),
            description: '',
            otherDescription:
              isWizardPage &&
              !statusApproved &&
              (notifications?.quotaIsExpired || notifications?.thereIsNotQuota
                ? t('PacksPage.packInformationOtherDescriptionExpired')
                : t('PacksPage.packPriceInformation')),
          }}
          cardHeaderJsx={cardHeaderJsx}
          cardBodyJsx={cardBodyJsx}
          buttonLabel={t('PacksPage.buy')}
          cardDivider={false}
          onClick={createOrder}
          buttonDisabled={
            tenant &&
            [TenantStatus.Inactiva, TenantStatus.BajaSolicitada].includes(
              tenant?.tenant_status_id,
            )
          }
          isButtonLoading={buttonLoading}
        />
        <p className="informative-text">
          {isWizardPage ? '' : t('PacksPage.informativeText')}
        </p>
        <p className="informative-text">
          {isWizardPage ? '' : t('PacksPage.packInformationFooterDescription')}
        </p>
        {preferenceId && (
          <Mercadopago
            instanceCheckoutMercadoPago={setInstanceMercadoPago}
            preferenceId={preferenceId}
          />
        )}
      </div>
    </FeatureFlagWrapper>
  );
};
